/*

  At some point we probably want this to use a graph query language,
  as in kgx, but for now we'll just call a user supplied function.

  So the advantage of useQuery is that it's a Reach Hook which will
  trigger a re-render when the query results change.
*/
import React from 'react'
import equal from 'fast-deep-equal'

export function useQuery (client, run) {
  const [results, setResults] = React.useState([])

  React.useEffect(() => {
    const rerun = () => {
      const out = run(client)
      if (!equal(out, results)) setResults(out)
    }

    rerun()

    client.on('change', rerun)
    client.on('change-user-data', rerun)
    return () => {
      client.off('change', rerun)
      client.off('change-user-data', rerun)
    }
  }, [client, run, results, setResults])

  return results
}
