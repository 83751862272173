import React from 'react'
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import { pct, useFocus, useQuery, accountSorter, Account } from './common'
import Vote from './Vote'

export function Notes (props) {
  const [focusId, setFocusId] = useFocus(props.params)
  const pp = Object.assign({ focusId, setFocusId }, props)

  if (focusId) return <Focused {...pp} />
  return <Unfocused {...pp} />
}

const cmp = (a, b) => {
  return b.score - a.score
}

// use client.user.edges as well as me.outs, in case the network
// isn't full enough?  So we at least the manual notes always?
const query = (client) => {
  const map = new Map()
  const data = []

  const user = client.user
  if (user) {
    const me = client.gg2.obtainNode(user.nodeid, false)
    if (me) {
      for (const [targetid, edgePayload] of me.outs) {
        const node = client.gg2.obtainNode(targetid)
        if (node) {
          map.set(targetid, {
            ...edgePayload,
            node,
            score: pct(edgePayload.score)
          })
        } else {
          // no node? I think that means it's about a merged node or
          // something.  Hrm.
        }
      }

      for (const edge of user.edges) {
        const node = client.gg2.obtainNode(edge.target)
        if (node) {
          map.set(edge.target, {
            ...edge,
            node,
            score: pct(edge.score)
          })
        }
      }

      data.push(...map.values())
      data.sort(cmp)
    } else {
      data.error = 'Loading...'
    }
  } else {
    data.error = 'Not yet logged in'
  }
  window.NotesData = data
  return data
}

function Unfocused ({ client, setFocusId, nav }) {
  const data = useQuery(client, query)
  if (data.error) return data.error

  const columns = [
    { title: 'Topic of the Note', field: 'node', formatter: reactFormatter(<Account nav={nav} />), sorter: accountSorter },
    { title: 'Score', field: 'score', width: 75 }, // formatter: pct
    { title: 'Origin', field: 'prov' }
    // date first/last done
    // factors
    // button or row-click?
  ]

  /*
  const rowClick = (event, row) => {
    const id = row.getData().node.id
    console.log('rowclick %o', id)
    setFocusId(id)
  }
  */

  const options = { height: 'calc(100vh - 9rem)' }
  const props = { columns, data, options }

  return (
    <div style={{ height: '100%' }} className='container is-fullhd'>
      <h2>{data.length} current notes by you</h2>
      <ReactTabulator {...props} />
    </div>
  )
}

function Focused (props) {
  return <Vote {...props} />
}
