import React from 'react'
import visiparam from 'visiparam'

export { visiparam as params }

visiparam.string('Focus Node', { id: 'focusNode', history: true })
visiparam.string('Focus Link', { history: false })
visiparam.boolean('Diagram Preference', { id: 'dia', history: true })
visiparam.string('Initial Limit', { default: '1000' })
visiparam.string('Server', { default: '' })

export function useParam (name, vp) {
  if (!vp) vp = visiparam
  const [value, setValue] = React.useState(vp.data(name))

  React.useEffect(() => {
    const handler = param => {
      if (param.label === name || param.id === name) {
        console.log('param %o changed to %o', name, param.current)
        setValue(param.current)
      }
    }
    vp.on('change', handler)
    return () => { vp.off('change', handler) }
  }, [setValue, name, vp])

  const wrappedSetValue = newValue => {
    if (typeof newValue === 'function') {
      const f = newValue
      const oldValue = vp.data(name)
      newValue = f(oldValue)
    }
    vp.data(name, newValue)
  }

  const linkToModified = newValue => {
    return vp.get(name).linkToModified(newValue)
  }

  return [value, wrappedSetValue, linkToModified]
}

/*
 -- this doesn't work quite right - I don't think nav-spa is expecting
    the value to have a slash in it, maybe, which gets URL escaped
    somewhere.

visiparam.nav.customPath = { parse, unparse }

function parse (path) {
  path = path.slice(1) // skip the leading slash
  if (path === '') return { }
  if (/^\w+$/.test(path)) return { page: path }
  return 'NotFound'
}

function unparse (state) {
  if (state.page) return '/' + state.page
  return '/'
}
*/
