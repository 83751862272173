import React from 'react'
import { Formik, Field, Form } from 'formik'
import { getGravatarHash } from 'gravatar-utils'
import { Face } from './formatting'
import Card from './Card'

export function useUser (client, notify) {
  const [user, setUser] = React.useState(client.user)
  React.useEffect(() => {
    const handle = () => {
      setUser(client.user)
      if (notify) notify(client.user)
    }
    client.on('change-user-data', handle)
    return () => { client.off('change-user-data', handle) }
  }, [client, setUser, notify])
  return user
}

export default function User (props) {
  const { client, nav } = props
  const user = useUser(client)
  const [message, setMessage] = React.useState('')
  // const firstField = React.useRef(null)

  console.log('rendering User, user=%O', user)

  if (user) return <UserInfo {...props} user={user} />

  // if (firstField.current) firstField.current.focus()

  const bodyClick = event => {
    console.log('body click')
    event.preventDefault()
  }

  return (
    <Formik
      initialValues={{
        email: '',
        cw_pw: '',
        ic: ''
      }}
      onSubmit={async values => {
        console.log('submitting', values)
        try {
          if (values.ic) {
            await client.acceptInvitation(values.ic)
          } else {
            await client.login(values)
          }
          console.log('logged in %O', client.user)
          nav.close()
        } catch (e) {
          if (e.code === 'BAD_AUTH') {
            setMessage('unknown user or incorrect password')
          } else {
            setMessage('Unknown error: ' + JSON.stringify(e))
          }
        }
      }}
    >
      {({ isSubmitting, handleReset }) => (
        <Form id='login-form'>

          <div className='modal-card'>
            <header className='modal-card-head'>
              <p className='modal-card-title'>Login</p>
              <button type='button' className='delete' aria-label='close' onClick={nav.close} />
            </header>

            <section className='modal-card-body' onClick={bodyClick}>

              <div className='columns'>
                <div className='column' style={{ borderRight: '1px solid gray' }}>

                  <p>Use email/password:</p>

                  <div className='field'>
                    <label className='label' htmlFor='email'>Email</label>
                    <Field
                      tabIndex='0'
                      id='email'
                      name='email'
                      placeholder='jane@acme.com'
                      type='email'
                      autoFocus
                    />
                  </div>

                  <div className='field'>
                    <label className='label' htmlFor='cw_pw'>Password</label>
                    <div className='control'>
                      <Field tabIndex='0' id='cw_pw' name='cw_pw' placeholder='' type='password' />
                    </div>
                  </div>
                </div>
                <div className='column'>
                  Or use:

                  <div className='field'>
                    <label className='label' htmlFor='ic'>Invitation Code</label>
                    <div className='control'>
                      <Field tabIndex='0' id='ic' name='ic' placeholder='' />
                    </div>
                  </div>
                </div>
              </div>

              <p>
                <b>
                  {message}
                </b>
              </p>

            </section>
            <footer className='modal-card-foot'>
              <div className='buttons'>
                <button type='button' className='button' onClick={nav.close}>Cancel</button>
                <button type='button' className='button' onClick={handleReset}>Reset</button>
                <button disabled={isSubmitting} type='submit' tabIndex='0' form='login-form' className='button is-primary'>Login</button>
              </div>
            </footer>
          </div>
        </Form>
      )}
    </Formik>
  )
}

function UserInfo ({ client, user, nav }) {
  const children = []
  /*
  for (const [key, value] of Object.entries(user)) {
    children.push(<p>{key}: {JSON.stringify(value)}</p>)
  }
  */
  // link over to InvitationsMade, Devices Authorizes, etc
  // change cw_pw
  // change name

  return (
    <div className='modal-card'>
      <header className='modal-card-head' onClick={nav.close}>
        <p className='modal-card-title'>User Information</p>
        <button type='button' className='delete' aria-label='close' />
      </header>

      <section className='modal-card-body'>
        {/* <button className="button" onClick={() => openModal('invite')}>View and Create Invitations</button> */}
        {children}
        <button onClick={() => nav.openModal('ChangePassword')} className='button is-rounded'>Change password</button>
        <br />
        <Email client={client} />
        <p>Avatar for that email (from <a href='https://en.gravatar.com/'>gravatar</a>): <Avatar client={client} /></p>
      </section>
      <footer className='modal-card-foot' onClick={nav.close}>
        <div className='buttons'>
          <button onClick={nav.close} className='button'>OK</button>
          <button onClick={() => client.logout()} className='button is-danger'>Log Out</button>
        </div>
      </footer>
    </div>
  )
}

const getGravatarAvatar = (email, { size, d } = {}) => `https://secure.gravatar.com/avatar/${getGravatarHash(email)}?${size ? `s=${size}&d=${d}` : ""}`;

export function Avatar ({ client, size, rounded }) {
  const user = useUser(client)
  const email = user.email
  const url = getGravatarAvatar(email || '', { size: 100, d: 'mp' })
  console.log('grav url', url)
  // console.log('gravatar url', url, email)
  return <Face {...{ url, size, rounded }} />
}

function Email ({ client }) {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState(client.user.email)
  const userChange = user => setEmail(user.email)
  const user = useUser(client, userChange)

  const close = async () => {
    setEmail(client.user.email)
    setOpen(false)
  }
  const save = async () => {
    await client.setUserData({ email, id: user.id })
    close()
  }

  if (open) {
    return (
      <div className='field'>
        <label className='label'>New Email</label>
        <div className='control'>
          <input
            type='email' value={email} onChange={e => setEmail(e.target.value)}
            onKeyUp={e => {
              if (e.keyCode === 13) save()
              if (e.keyCode === 27) close()
            }}
            autoFocus
          />
          <button className='button is-small is-rounded' onClick={save}>Save</button>
          <button className='button is-small is-rounded' onClick={close}>Cancel</button>
        </div>
      </div>
    )
  } else {
    return <p>Email: <b>{email || <em>none</em>}</b>  <button className='button is-small is-rounded' onClick={() => setOpen(true)}>Change</button></p>
  }
}

export function ChangePassword ({ client, nav }) {
  const [pw1, setPW1] = React.useState('')
  const [pw2, setPW2] = React.useState('')

  const save = async () => {
    await client.setUserData({ cw_pw: pw1, id: client.user.id })
    // give some kind of confirmation?
    nav.close()
  }
  const buttons = (
    <span>
      <button onClick={nav.close} className='button is-danger'>Cancel</button>
      <button onClick={save} disabled={pw1 !== pw2 || !pw1} className='button is-success'>Switch to new password</button>
    </span>
  )
  return (
    <Card title='Set Password' buttons={buttons}>

      <div className='field'>
        <label className='label'>New Password</label>
        <div className='control'>
          <input type='password' value={pw1} onChange={e => setPW1(e.target.value)} />
        </div>
      </div>

      <div className='field'>
        <label className='label'>Confirm New Password</label>
        <div className='control'>
          <input type='password' value={pw2} onChange={e => setPW2(e.target.value)} />
        </div>
      </div>

    </Card>
  )
}
