import React from 'react'
import { Heading, Section } from 'react-bulma-components'
import { brand } from './branding'
import Card from './Card'

export default function About () {
  return (
    <Card title={'About ' + brand.name}>
      <Section>
        <Heading size={5}>Warning</Heading>
        <p>This site is under development. <b style={{ color: 'red' }}>Use at your own risk.</b></p>

        <p className='mt-4'>This site <b>may lose data</b> due to bugs.</p>

        <p className='mt-4'>All the data you enter on this site is <b>intended to be public.</b>  Access control may be added at some point.</p>

        <p className='mt-4'>Additional terms and disclaimers available using links at the bottom of the page.</p>

        <p className='mt-4'>Please use the <a href='/?modals=feedback'>feedback link</a> to report bugs, offer suggestions, etc.</p>

      </Section>

    </Card>
  )
}
