/*
  These are the components that occur only once in the app and can be
  used as modals or pages, summoned by their name.  These names end up
  visible to the user, as part of the URL.  If you want a different
  internal name, that's fine, just use the fact that pages & modals
  are maps.
*/

import About from './About'
import Activity from './Activity'
import Feedback from './Feedback'
import How from './How'
import Invite from './Invite'
import Explore from './Explore'
import Search from './Search'
import Settings from './Settings'
import Splash from './Splash'
import Test from './Test'
import UseInvitation from './UseInvitation'
import Vote from './Vote'
import User, { ChangePassword } from './User'
import { EditNetworkTags } from './Networks'
import { Guests, AddGuest } from './Guests'
import { Notes } from './Notes'
import Reports from './Reports'

const components = {
  About,
  Activity,
  AddGuest,
  ChangePassword,
  EditNetworkTags,
  Feedback,
  Guests,
  How,
  Invite,
  Explore,
  Notes,
  Reports,
  Search,
  Settings,
  Splash,
  Test,
  UseInvitation,
  User,
  Vote
}

export default components
