import React from 'react'
import components from './globalComponents'

export default function Test (props) {
  const { nav } = props

  const ct = []
  for (const [name] of Object.entries(components)) {
    ct.push(
      <span key={name}>
        <a href={nav.linkForPage(name)}>{name} as page</a>,
        <a href={nav.linkForModal(name)}>{name} as modal</a>,
        <a href={nav.linkForPage(name, { sub: true })}>{name} as sub</a>,
        <a href={nav.linkForPage('Test/About/' + name)}>{name} as a/a/sub</a>
      </span>
    )
  }

  return (
    <div>
      <span>Builtin Tests</span>
      <ol>
        {ct.map(x => <li>{x}</li>)}
      </ol>
    </div>
  )
}
