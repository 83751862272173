import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import busy, { Busy } from './busy'
import { params } from './params'
import { Client } from 'osca-client'

let url = (
  params.server.current ||
    window.localStorage.birdcageURL ||
    'wss://s1.trustlamp.com/api'
)
if (!url.startsWith('ws')) {
  url = 'wss://' + url
}

const client = new Client({ url, busy })

// it's hard to know where to set this.  too small, and we're unable
// to see things we should; too big and it's too slow. We could
// persist user settings, but what if they set it too big?  It's a bit
// like "safe mode" booting when an OS is messed up -- we need a way
// for the user to lower the limit, when it's too high.

client.limit = parseInt(params.initialLimit.current) || 1000

client.on('change-user-data', user => {
  if (typeof window.Canny === 'undefined') {
    console.warn('Canny not defined')
  } else {
    if (user) {
      window.Canny('identify', {
        appID: '5f47e77db3dab11e4319362e',
        user: {
          email: user.email,
          name: user.screenName,
          id: user.id,
          // avatarURL: user.avatarURL,
          created: new Date(user.cr_created).toISOString()
        }
      })
    }
  }
})

params.string('Current UI page', { id: 'page' })

const props = { client, params }

ReactDOM.render(
  <React.StrictMode>
    <Busy />
    <App {...props} />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
