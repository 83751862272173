import { ago } from './common'

export function AboutNode ({ id, node }) {
  const blocks = []

  for (const acct of node.data('accounts') || []) {
    if (acct.platform === 'local') {
      blocks.push(
        <div key={acct.platform} className='column'>
          <p>Details from <b>TrustLamp User Database</b></p>
          <AboutLocalNode u={acct} />
        </div>
      )
    } else if (acct.platform === 'twitter') {
      blocks.push(
        <div key={acct.platform} className='column'>
          <p>Details from cache of <b>Twitter API</b></p>
          <AboutTwitterNode u={acct} />
        </div>
      )
    }
  }

  if (blocks.length === 0) {
    blocks.push(<p key={node.id}>No detail available for nodeid={node.id}</p>)
  }

  return (
    <div style={{ marginTop: '0rem' }} className='section'>
      <div className='columns is-centered'>
        {/* blocks.map(x => <div className="column">{x}</div>) */}
        {blocks}
      </div>
    </div>
  )
  /*
  const rows = []

  rows.push(['id', id])

  let v
  v = node.data('screenName')
  if (v) rows.push(['Screen Name', v])

  if (tw && tw.screen_name) {
    rows.push(['Twitter account', <a href={'https://twitter.com/' + tw.screen_name}>{tw.screen_name}</a>])
    rows.push(['json', JSON.stringify(tw)])
  }

  return (
    <table>
      <tbody>
        {rows.map(([key, value]) => (
          <tr key={key}>
            <th>{key}</th>
            <td>{value}</td>
          </tr>
        ))}
    </tbody>
    </table>
  ) */
}

function AboutLocalNode ({ u }) {
  const t = []
  for (const [key, value] of Object.entries(u)) {
    t.push(<span key={key}>{key}: {JSON.stringify(value)}<br /></span>)
  }
  return (
    <div className='card'>
      <div className='card-content'>
        Local profiles coming soon.
        <hr />
        {t}
      </div>
    </div>
  )
}

function AboutTwitterNode ({ u }) {
  const created = new Date(u.created_at)

  const t = []
  for (const [key, value] of Object.entries(u)) {
    t.push(<><span key={key}>{key}: {JSON.stringify(value)}</span><br /></>)
  }
  return (
    <div className='card'>
      <div className='card-content'>
        <div className='media'>
          <div className='media-left'>
            <figure className='image XXis-128x128'>
              <img
                style={{ width: 200, height: 200 }}
                src={u.profile_image_url_https.replace('_normal', '_400x400')} alt='user profile'
              />
            </figure>
          </div>
          <div className='media-content'>
            <p className='title is-4'>{u.name}
              {u.verified && <span className='ml-6' style={{ fontSize: '80%', background: 'rgb(200,200,200)' }}>Twitter verified user</span>}
              {u.protected && <span className='ml-5'>[protected]</span>}
            </p>
            <p className='subtitle is-6'>@{u.screen_name}
              <span className='ml-5'>
                <a target='_blank' rel='noopener noreferrer' href={'https://twitter.com/' + u.screen_name}>
                  View on Twitter
                </a>
              </span>
            </p>
          </div>
        </div>

        <div className='content'>
          {u.description}
          <br className='mb-3' />
          <span className='mr-5'><b>{u.friends_count}</b> following</span>
          <span className='mr-5'><b>{u.followers_count}</b> followers</span>
          <span className='mr-5'><b>{u.statuses_count}</b> tweets</span>
          <span className='mr-5'><b>{u.favourites_count}</b> likes</span>
          <br />
          Account created: <time dateTime={created}>{ago(created)}</time>
          <br />
          {u.location && <span className='mr-5'>Location: {u.location}</span>}
          {u.url && <span className='mr-5'>Website: <a target='_blank' rel='noopener noreferrer' href={u.url}>{u.url}</a></span>}
          {u.cursors &&
            <>
              <br />
              <Cursors {...u.cursors} />
            </>}
        </div>
      </div>
    </div>
  )
}

function Cursors ({ tweets, friendsList, likes }) {
  return (
    <span>
      <span>Stored: </span>
      <Facet facet={tweets} name='tweets' />
      <Facet facet={friendsList} name='followings' />
      <Facet facet={likes} name='likes' />
    </span>
  )
}

function Facet ({ facet, name }) {
  return (facet
    ? <span className='ml-3'>
      {facet.done
        ? <span><b>all</b> {facet.items} available {name} </span>
        : <span><b>{facet.items}</b> {name} </span>}
      {/* (from {ago(facet.lastUsed)}) */}
    </span> : <span className='ml-3'><b>No</b> {name} fetched</span>)
}
