import React from 'react'
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import Card from './Card'
import { params } from './params'
import { useForm } from 'react-hook-form'

export function Guests ({ client, nav }) {
  const guests = useGuests(client, nav)

  const columns = [
    { title: 'Name', field: 'name', width: null, cellClick: nameClick },
    { title: 'Used', field: 'claimed', width: 45 },
    { title: 'Actions', field: 'actions', width: null, formatter: reactFormatter(<Buttons />) },
    { title: 'Created', field: 'created', XXsorter: 'datetime', width: null }
  ]

  const data = [...guests]
  const options = {}
  const props = { columns, data, options }

  return (
    <div style={{ height: '100%' }} className='container is-fullhd'>
      <button onClick={() => nav.openPage('Explore/Guests/AddGuest')} className='button is-primary is-rounded is-small mt-3 mb-3 ml-4'>Add a guest</button>
      <ReactTabulator {...props} />
    </div>
  )
}

function nameClick (event, cell) {
  const guest = cell.getRow().getData()
  guest.select()
}

// This is used as a tabulator data object, so all the
// column fields have to be dumb (no getters)
class Guest {
  constructor (data, client, nav) {
    // console.log('Guest %o', data)
    Object.assign(this, data)
    this.nav = nav
    this.name = this.guestData.screenName
    // this.id = client.siteurl + '/u/' + this.guestId // a bit sketchy!
    this.id = 'https://trustlamp.com/u/' + this.guestId // a bit sketchy!
    this.actions = ''
    this.node = client.gg2.obtainNode(this.id)
    console.log('Guest %o %o', this.id, this.node)
    if (this.node) {
      const payload = this.node.payload
      if (payload && payload.accounts?.length) this.claimed = payload.accounts[0].cr_pwChanged
    }
  }

  select () {
    if (params.data('focusNode')) {
      params.data('focusNode', '')
    } else {
      params.data('focusNode', this.id)
    }
  }

  edit () {
    params.data('focusNode', this.id)
    this.nav.openModal('Vote')
  }

  show () {
    // How can we open a one-time Modal from here?  We don't have
    // access to the rendering tree....
    //
    // PORTALS!
    alert(this.url)
  }

  cancel () {
    // client.cancelInvitation
    alert('Not really implemented')
  }

  disable () {
    // disable / enable logins
    //
    // is that a priv'd update?

    alert('Not really implemented')
  }
}

// old API
function useGuests (client, nav) {
  const getInvs = React.useCallback(() => {
    const invs = client.user && client.user.invitationsMade
    console.log('updated invs = %O', invs)
    return invs
  }, [client])

  const [invs, setInvs] = React.useState(getInvs())
  const [, forceUpdate] = React.useReducer(x => x + 1, 0)

  React.useEffect(() => {
    const handleChange = () => {
      console.log('useGuests handleChange')
      setInvs(getInvs())
      forceUpdate() // brutal -- got a better way?
    }
    client.on('change-user-data', handleChange)
    client.on('change', handleChange)
    return () => {
      client.off('change-user-data', handleChange)
      client.off('change', handleChange)
    }
  }, [getInvs, setInvs, client])

  console.log('useGuests returning', invs)
  if (!invs) return []
  return invs.map(i => new Guest(i, client, nav))
}

function Buttons ({ cell }) {
  const guest = cell.getRow().getData()
  return (
    <span>
      <button onClick={() => guest.show()} className='button is-small is-rounded is-primary'>See Invitation URL</button>
      <button onClick={() => guest.edit()} className='button is-small is-rounded is-primary'>Edit</button>
      {guest.claimed
        ? <button onClick={() => guest.disable()} className='button is-small is-rounded is-danger'>Disable Account</button>
        : <button onClick={() => guest.cancel()} className='button is-small is-rounded is-danger'>Cancel invitation</button>}
    </span>
  )
}

export function AddGuest ({ client, nav }) {
  const { register, handleSubmit, watch, errors } = useForm()

  const onSubmit = async values => {
    nav.closeModal()

    // this should be in client.js

    // should be createEntityPage, I think
    const inv = await client.createInvitation({ screenName: values.name })
    console.warn('CREATED', inv)
    // const id = client.siteurl + '/u/' + inv.guestId // a bit sketchy
    const id = 'https://trustlamp.com/u/' + inv.guestId // a bit sketchy!
    client.user.edges.push({
      prov: 'invited',
      reason: 'Assuming a high level of trust in someone you invite. Change this to whatever you actually want.',
      score: 0.9,
      target: id
    })
    await client.setUserData()
    params.data('focusNode', id)
    nav.openPage('Explore/Guests')
    nav.openModal('Vote')
  }

  const buttons = (
    <span>
      <button onClick={nav.close} className='button'>Cancel</button>
      <input className='button' type='submit' value='Create Guest Account' />
    </span>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='New Guest' buttons={buttons}>
        <p>Enter guest's name (screen name). This will be public.  At some point they'll be able to change it, but that's not implemented yet.</p>
        <input autoFocus name='name' placeholder='name goes here' ref={register({ required: true })} />
        {errors.name && <span><b>This field is required</b></span>}
      </Card>
    </form>
  )
}
