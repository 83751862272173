/*

 This is a bit odd -- the point is to trigger a re-render in any
 render function that is using client data, whenever client data
 changes.

 A cleaner approach would be a kind of useQuery which triggered a
 re-render whenever the query results changed.  But we don't have a
 graph query language in the current system. So we'll use this for
 now.

*/
import React from 'react'

export function useClient (client) {
  const [, forceUpdate] = React.useReducer(x => x + 1, 0)

  React.useEffect(() => {
    client.on('change', forceUpdate)
    client.on('change-user-data', forceUpdate)
    return () => {
      client.off('change', forceUpdate)
      client.off('change-user-data', forceUpdate)
    }
  }, [forceUpdate, client])

  return undefined // this is a hook used for its side-effect
}
