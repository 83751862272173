import React from 'react'
import classnames from 'classnames'
import { brand } from './branding'
// import { useModals } from './modals'
import { Avatar, useUser } from './User'
import { useFocus } from './useFocus'
import { useClient } from './useClient'
import { U } from './formatting'
// import Dropdown from 'react-bulma-components/lib/components/dropdown'
import Icon from 'react-bulma-components/lib/components/icon'

const searchIcon = '🔍'

export default function Navbar ({ params, client, nav, back }) {
  const user = useUser(client)
  const { modalName, openPage, openModal, linkForModal } = nav

  const itemClasses = ['button', 'is-medium']
  const buttons = []
  const endButtons = []

  const ModalButton = (name, label) => {
    if (label === undefined) label = name
    const classNames = [...itemClasses]
    if (modalName === name) classNames.push('is-active')
    return (
      <a
        style={{ flex: '0 1 3rem' }}
        key={label}
        className={classnames(...classNames)}
        href={linkForModal(name)}
        onClick={() => {
          // closeModal({all: true}) // do we need this?
        }}
      >{label}
      </a>
    )
  }

  /*
  const PageButton = (name, label) => {
    if (label === undefined) label = name
    const classNames = [...itemClasses]
    if (pageName === name) classNames.push('is-active')
    return (
      <a
        key={label}
        className={classnames(...classNames)}
        href={linkForPage(name)}
        onClick={e => {
          // closeModal({all: true}) // do we need this?

          // don't just rely on the link because, alas, some state
          // might changed (like setFocus) which currently wont cause
          // us to re-render, so we don't know about that state.
          e.preventDefault()
          openPage(name)
        }}
      >{label}</a>
    )
  }

  // maybe this stuff could be passed in as props, to make this
  // a generic nav bar?

  buttons.push(PageButton('Reports'))
  buttons.push(PageButton('Notes'))
  buttons.push(PageButton('Network'))
  buttons.push(PageButton('Activity'))
  */
  if (user) buttons.push(ModalButton('Search', searchIcon))

  if (user) {
    endButtons.push(<UserDropDown key='user-drop' {...{ user, nav, client }} />)
  } else {
    endButtons.push(ModalButton('About'))
    endButtons.push(<button key='user' className='button is-medium is-primary' onClick={() => openModal('User')}>Login</button>)
  }

  const home = (
    <a
      className='button is-medium' href='/'
      style={{
        flex: '0 1 3em',
        paddingTop: 'calc(0.5em + 5px)',
        paddingLeft: '0.5em',
        paddingRight: '0.5em'
      }}
      onClick={e => {
        // console.log('RESET?')
        openPage('', { fresh: true })
        e.preventDefault()
      }}
    >
      <img src={brand.logo} alt='Trust' width='28' height='28' />
    </a>
  )

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {back || home}
      {buttons}
      <Focus style={{ flex: '1 2 300px', overflow: 'hidden' }} params={params} client={client} />
      {endButtons}
    </div>
  )
}

/*
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/"
           onClick={e => {
             console.log('RESET?')
             openPage('', {fresh: true});
             e.preventDefault()
           }}>
          <img src={brand.logo} alt="Trust" width="28" height="28" />
          <strong style={{paddingLeft: '0.5rem'}}>{brand.name}</strong>
        </a>
        <div
          role="button" aria-label="menu" aria-expanded="false"
          className={classnames('navbar-burger', {'is-active': active})}
          onClick={e => setActive(!active)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div className={classnames('navbar-menu', {'is-active': active})}>
        <div className="navbar-start">
          {buttons}
          <Focus params={params} client={client}/>
        </div>
        <div className="navbar-end">
          {endButtons}
          <button className={"navbar-item button is-medium" + (user ? '' : ' is-primary')} onClick={() => { openModal('User'); setActive(false) }}>{userButtonBody}</button>
        </div>
      </div>
    </nav>
  )
}
*/

const itemClasses = ['button', 'is-medium']
const PageButton = (nav, name, label) => {
  if (label === undefined) label = name
  const classNames = [...itemClasses]
  if (nav.pageName === name) classNames.push('is-active')
  return (
    <a
      key={label}
      style={{ flex: 1 }}
      className={classnames(...classNames)}
      href={nav.linkForPage(name)}
      onClick={e => {
        // closeModal({all: true}) // do we need this?

        // don't just rely on the link because, alas, some state
        // might changed (like setFocus) which currently wont cause
        // us to re-render, so we don't know about that state.
        e.preventDefault()
        nav.openPage(name)
      }}
    >{label}
    </a>
  )
}

export function Tabs ({ params, client, nav, tabs }) {
  const buttons = []

  tabs.forEach(tab => buttons.push(PageButton(nav, tab)))

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {buttons}
    </div>
  )
}

function Focus ({ params, client, style }) {
  useClient(client)
  const [focusId, setFocusId] = useFocus({ params })
  let msg = ''
  if (focusId) {
    const node = client.gg2.obtainNode(focusId)
    if (node) {
      msg = <><U node={node} link={false} /></>
      // msg = 'Focus: ' + node.data('screenName')
    } else {
      msg = 'Focus: missing ' + focusId
    }
  }

  let pre = []
  if (msg) pre = <button className='mr-3 delete' onClick={() => setFocusId('')} />
  // if (!msg) msg = <span><img src={brand.logo} alt="Trust" width="28" height="28" /> {brand.name}</span>
  if (!msg) msg = <div style={{ width: '100%' }} className='has-text-centered'>{brand.name}</div>

  return (
    <div className='is-medium button' style={{ ...style, border: 'none', cursor: 'inherit', justifyContent: 'left' }}>
      {pre}{msg}
    </div>
  )
}

/*
function Search ({params, client}) {
  const [focusId] = useFocus({params})
  const [open, setOpen] = React.useState(false)
  // how do we close when tabs are switched?  //  params('searchOpen') ?
  // which focus can use to not show itself as well.

  return (
    <>
      <span className="navbar-item">
        <button className="navbar-item button is-medium" onClick={() => setOpen(!open)} >
          {searchIcon}                                                                 </button>
        { open && <input className="ml-2" type="text" onSubmit={e => alert(e.value)}/> }
      </span>
    </>
  )
}
*/

// consider https://github.com/fraserxu/react-dropdown/blob/master/index.js
function UserDropDown ({ user, nav, client }) {
  // console.log('navbar udd rendering')
  const [down, setDown] = React.useState(false)

  let expanded = []
  if (down) {
    expanded = (
      <div style={{
        position: 'absolute',
        right: 0,
        top: '3.0em',
        background: 'white',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '12px 16px',
        zIndex: 1
      }}
      >
        <button
          className='button mb-2' onClick={() => {
            // console.log('navbar about')
            setDown(false)
            nav.openModal('About')
          }}
        >About
        </button><br />
        <button
          className='button mb-2' onClick={() => {
            setDown(false)
            nav.openModal('ChangePassword')
          }}
        >Change pw
        </button><br />
        <button
          className='button mb-2' onClick={() => {
            setDown(false)
            client.logout()
          }}
        >Logout
        </button>
        {nav.legal}
      </div>
    )
  }

  const closeSoon = e => {
    // console.log('navbar closeSoon', e)
    // why oh why do we need to wait 200ms?  Well, 100 isn't long
    // enough. If we do that, we never get click events.  Waaaaaaah.
    setTimeout(() => setDown(false), 200)
  }

  return (
    <>
      <button style={{ padding: '0 0.5em 0 0.5em' }} onClick={() => setDown(!down)} onBlur={closeSoon} className='is-medium button'>
        <Avatar rounded size='2.5em' client={client} />
        <Icon icon='angle-down' size='small' />
      </button>
      {expanded}
    </>
  )
}
