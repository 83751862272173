import React from 'react'
import 'react-tabulator/lib/styles.css' // required styles
import 'react-tabulator/lib/css/tabulator.min.css' // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import { ModalCard, openModal } from './modals'
import { params } from './params'

export default function Invite ({ client, closeModal }) {
  console.log('Invite.js rednering')
  // const getInvs = React.useCallback(() => (
  // client.user && client.user.invitationsMade), [client])
  const getInvs = React.useCallback(() => {
    const invs = client.user && client.user.invitationsMade
    console.log('updated invs = %O', invs)
    return invs
  }, [client])

  const [invs, setInvs] = React.useState(getInvs())

  React.useEffect(() => {
    const handleChange = () => setInvs(getInvs())
    client.on('change-user-data', handleChange)
    return () => { client.off('change-user-data', handleChange) }
  }, [getInvs, setInvs, client])

  const columns = [
    { title: 'Name', field: 'name', width: 150 },
    { title: 'Actions', field: 'actions', width: '35rem', formatter: reactFormatter(<Buttons />) },
    { title: 'Created', field: 'created', XXsorter: 'datetime', width: '20rem' }
    // { title: 'Starting Score', field: 'score', hozAlign: 'center' },
    /*
    { title: 'URL', field: 'actions', hozAlign: 'center', width: 100, formatter: reactFormatter(
        <Show/>
    )},
    { title: 'Cancel', field: 'actions', hozAlign: 'center', width: 100, formatter: reactFormatter(
        <Cancel/>
    )}
    */
  ]

  const data = []

  for (const inv of invs || []) {
    // console.log('inv', inv)
    if (!inv.guestData) continue // malformed?  obsolete?
    const id = `https://trustlamp.com/u/${inv.guestId}` // HACK!

    data.push({
      name: inv.guestData.screenName,
      created: inv.created,
      score: inv.guestData.score,
      actions: '',
      edit: () => {
        params.data('focusNode', id); openModal('Vote')
      },
      cancel: () => {
        client.user.invitationsMade = client.user.invitationsMade.filter(x =>
          (x !== inv))
        client.setUserData()
        console.log('cancelled, im = ', JSON.stringify(client.user.invitationsMade, null, 2))
      },
      show: () => {
        alert(inv.url)
      }
    })
  }

  const options = {}
  const props = { columns, data, options }
  return (
    <ModalCard title='Who Are You Inviting?'>
      <New client={client} />
      <h2>Current Invitiations</h2>
      <ReactTabulator {...props} />
    </ModalCard>
  )
}

function New ({ client }) {
  // button that brings up
  // form with Name & Score & Submit
  const [name, setName] = React.useState('')
  const [score, setScore] = React.useState(90)
  const submit = () => {
    // set the reverse score?  for now the server does that
    // const edges = [{source, target, prov, score}]
    client.createInvitation({ screenName: name, score })
    setName('')
  }
  return (
    <>
      <p className='mb-4'>Please be careful who you invite. We don't need to know who they are, because we are trusting that you do. Assume that everyone can see who you are inviting. Pick a screen name that you'll positively recognize and will make sense to any mutual contacts. (Later, we'll have a way to change screen names).</p>
      <p className='mb-4'>Once created, invitation URLs are available using the "show" button in the table below. It's up to you to send the URL to the invited person over a private channel (email, sms, signal, etc).</p>
      <p className='mb-4'>If you cancel an invitation, that user will no longer have access to the system (for now). If they are already using the system, you probably don't want to do that!</p>
      <form onSubmit={submit}>
        <div className='mb-4'>
          <label> Their screen name: <input type='text' size='30' name='name' value={name} onChange={e => setName(e.target.value)} /> </label>
        </div>
        <div className='mb-4'>
          <label> Your starting score for them: <input type='text' size='2' name='score' value={score} onChange={e => setScore(e.target.value)} /> </label><br />
        </div>
        <input className='mb-4' type='submit' value='Create Invitation' />
      </form>
    </>
  )
}

function Cancel (props) {
  const rowData = props.cell._cell.row.data
  return <button onClick={() => rowData.cancel()}>Cancel</button>
}

function Show (props) {
  const rowData = props.cell._cell.row.data
  return <button onClick={() => rowData.show()}>Show</button>
}

function EditButton ({ cell, link, client }) {
  const id = cell.getValue()
  return (
    <button
      onClick={() => { params.data('focusNode', id); openModal('Vote') }}
      className='button is-small'
    >Edit
    </button>
  )
}

function Buttons ({ cell }) {
  const rowData = cell.getRow().getData()
  return (
    <span>
      <button onClick={() => rowData.show()} className='button is-small is-rounded is-primary'>See URL</button>
      <button onClick={() => rowData.edit()} className='button is-small is-rounded is-primary'>Edit</button>
      <button onClick={() => rowData.cancel()} className='button is-small is-rounded is-danger'>Cancel</button>
    </span>
  )
}

/* from docs

function SimpleButton (props) {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value || 'Edit | Show';
  return <button onClick={() => alert(rowData.name)}>{cellValue}</button>
}

*/
