import React from 'react'
// import { useFocus } from './useFocus'
// import { useQuery } from './useQuery'
import { useClient } from './useClient'
import Card from './Card'
import { U, pct } from './common'
// import Diagram from './Diagram'
import { Content } from 'react-bulma-components'
import { AboutNode } from './AboutNode'

export default function Reports (props) {
  if (props.nav.focusId) return <Focused {...props} />
  return <Unfocused {...props} />
}

function Unfocused ({ client, nav }) {
  useClient(client)
  if (!client || !client.user) return 'loading...'

  const ids = [
    'https://trustlamp.com/u/53',
    'https://trustlamp.com/u/87',
    'https://twitter.com/i/user/61592079',
    'https://twitter.com/i/user/11435642',
    'https://twitter.com/i/user/1382974668',
    'https://twitter.com/i/user/20131383'
  ]

  const node = client.gg2.obtainNode(client.user.nodeid)
  if (!node) return 'loading...'

  return (
    <Card title='Select focus for report'>
      <Content>
        <p>Use <b>search</b>, <b>explore</b>, or try some of these:</p>
        <ul>
          {ids.map(id => {
            const node = client.gg2.obtainNode(id, null)
            if (!node) return <li>...</li>
            return <li key={id}><U {...{ node, nav }} /></li>
          })}
        </ul>
      </Content>
    </Card>
  )
}

/*
 moved to Explore tab
function Focused (props) {
  return (
    <>
      <button onClick={() => alert('yo')}>yo</button>
      <Diagram slot='cy1' {...props} />
    </>
  )
}
*/

function Focused (props) {
  useClient(props.client)
  const node = props.client.gg2.obtainNode(props.nav.focusId)
  if (!node) return '...'
  return (
    <>
      <AboutNode id={props.nav.focusId} node={node} />
      <MTR node={node} {...props} />
      <Outs node={node} {...props} />
    </>
  )
}

function MTR ({ node, client, nav }) {
  const out = []
  for (const [s, t, p] of client.mtrPath(node)) {
    console.log('mtrPath retrun entry %o', { s, t, p })
    out.push(
      <li key={s.id + ' ' + t.id}>
        <U node={s} nav={nav} />
        stated/implied score {pct(p.score)} for
        <U node={t} nav={nav} /> [details-tbd]
      </li>
    )
  }
  return (
    <div className='container is-hd'>
      <Card title={<span>Most trusted path [<a href={nav.linkForPage('Explore')}>explore</a>]</span>}>
        <ol>{out}</ol>
      </Card>
    </div>
  )
}

function Outs ({ node, client, nav }) {
  const out = []
  for (let [t, p] of node.outs) {
    t = client.gg2.obtainNode(t)
    out.push(
      <li key={node.id + ' ' + t.id}>
        <U node={node} nav={nav} />
        stated/implied score {p.score} for
        <U node={t} nav={nav} />
        (to ... Activity but thats multiple parms)
      </li>
    )
  }
  return (
    <div className='container is-hd'>
      <Card title={<span>Ratings they've stated / implied [<a href={nav.linkForPage('Explore')}>explore</a>]</span>}>
        {out.length ? <ol>{out}</ol> : <i>none available</i>}
      </Card>
    </div>
  )
}
