import React from 'react'
import equal from 'fast-deep-equal'
import Sortable from 'sortablejs'
import handle from './draggable-handle2.png'
import ToggleButton from './ToggleButton'
import { openModal } from './modals'

// rename "worlds" to "networks", probably?

const defaultNetworks = [
  'Family',
  'School',
  'Work',
  'Friends',
  'Politics',
  'Sports',
  'News Media',
  'Social Media',
  'Music',
  'Movies & Shows'
]

export function userNetworks (client) {
  return client.user.networks || defaultNetworks
}

export function SelectNetworks ({ client, selected, setSelected }) {
  const networks = userNetworks(client)

  // doesn't work
  // const [selected, setSelected] = React.useState({})

  const buttons = networks.map(name => <ToggleButton key={name} selected={selected} setSelected={setSelected} name={name} />)
  buttons.push(<button key='extra' className='button' onClick={e => openModal('EditNetworkTags')}><b>Customize Tags</b></button>)

  console.log('buttons: %O', buttons)

  return (
    <div className='xtabs is-toggle is-toggle-rounded'>
      <ul>{buttons}</ul>
    </div>
  )
}

export class EditNetworkTags extends React.Component {
  constructor (props) {
    super(props)
    this.state = { networks: defaultNetworks, newName: '' }
    this.listRef = React.createRef()

    this.save = this.save.bind(this)
    this.add = this.add.bind(this)
    this.onClientChange = this.onClientChange.bind(this)
  }

  onClientChange () {
    if (!this.props.client) return
    if (!this.props.client.user) return

    const networks = userNetworks(this.props.client)
    const state = { networks }
    console.error('remote state = %o', networks)

    if (equal(state, this.remoteState)) return
    if (this.remoteState) {
      /*
        Alternative would be to have the server share the UI state between sessions, before saving.  That's probably not worth it.
      */
      // alert('It looks like you just changed your answers in another session. You can "Cancel" then "Edit" again to get updated information here. If you "Save" here, the answers from the other session will be over-written.')
    } else {
      console.error('setState %o', state)
      this.setState(state)
    }
    this.remoteState = state
  }

  makeUnsortable () {
    if (this.sortable) {
      console.log('making unsortable')
      this.sortable.destroy()
      this.sortable = null
    }
  }

  makeSortable () {
    this.makeUnsortable()
    console.log('making sortable')
    this.sortable = new Sortable(this.listRef.current, {
      handle: '.my-handle',
      dataIdAttr: 'data-text',
      onSort: e => {
        // console.log('sorted', e)
        console.log('moved %o to %o', e.oldDraggableIndex, e.newDraggableIndex)
        const oldpos = e.oldDraggableIndex
        const newpos = e.newDraggableIndex
        console.log('oldpos', oldpos, 'newpos', newpos)
        const old = this.state.networks
        console.log('old = ', old.join(', '))
        const before = old.slice(0, oldpos)
        console.log('before = %o', before.join(', '))
        const me = old[oldpos]
        console.log('me = %o', me)
        const after = old.slice(oldpos + 1)
        console.log('after = %o', after.join(', '))
        const next = [...before, ...after]
        console.log('next without me = %o', next.join(', '))
        next.splice(newpos, 0, me)
        // console.log('sorted: POS: %o BEFORE: %o ME: %o AFTER: %o', pos before.join(', '), me, after.join(', '))
        console.log('next %o', next.join(', '))
        this.setState({ networks: next })
        // console.log('now: %o', sortable.toArray())

        // we just changed the order, and they'll change it again!
        // this.makeUnsortable()
      }
    })
  }

  componentDidMount () {
    this.onClientChange()
    this.props.client.on('change-user-data', this.onClientChange)
    this.makeSortable()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.client !== this.props.client) {
      prevProps.client.off('change-user-data', this.onClientChange)
      this.componentDidMount()
    }
    this.makeSortable()
  }

  componentWillUnmount () {
    this.makeUnsortable()
    this.props.client.off('change-user-data', this.onClientChange)
  }

  save () {
    if (equal(this.state, { networks: defaultNetworks })) {
      delete this.user.networks
    } else {
      this.user.networks = this.state.networks
    }
    this.props.client.setUserData()
  }

  render () {
    console.error('<Networks> render, state = %o', this.state)
    const networks = this.state.networks
    console.error('<Networks> render, networks = %o', networks)
    if (!networks) return ''

    // re-order
    // add
    // remove -- if not used?

    return (
      <div className='modal-card'>
        <header className='modal-card-head' onClick={this.props.closeModal}>
          <p className='modal-card-title'>Tags for your networks / context</p>
          <button type='button' className='delete' aria-label='close' />
        </header>

        <section className='modal-card-body'>

          <p className='mb-4'>Please use words or phrases that will make sense to you and the people connected to you. It's okay if a tag means different things in different communities, but things may get confusing if the communities connect. Term that imply a different community to each person (like "family" or "school") are fine.</p>

          <div ref={this.listRef}>Available tags:
            {networks.map(x => <p
              key={x}
              dataText={x}
              className='ml-4'
              style={{ border: '0px solid gray', margin: '5px' }}
                               >
              <span style={{ cursor: 'grab' }} className='my-handle'><img src={handle} alt='handle for dragging' width={32} height={32} /></span>
              <span className='button is-rounded'>{x}</span>
              <button
                className='button is-small'
                onClick={e => {
                  const w2 = networks.filter(e => e !== x)
                  console.log('w2 = %o', w2)
                  this.setState({ networks: w2 })
                }}
              >hide
              </button>
            </p>)}
          </div>

          <p>New tag name: <input
            className='ml-4 mr-4'
            type='text'
            value={this.state.newName}
            size='30'
            onChange={e => this.setState({ newName: e.target.value })}
            onKeyUp={e => { if (e.keyCode === 13) this.add() }}
                           />

            <button
              className='button'
              disabled={!this.state.newName}
              onClick={this.add}
            >Add
            </button>
          </p>

          <div>Suggested tags:
            {defaultNetworks
              .filter(x => !networks.includes(x))
              .map(x => <span className='ml-4'>
                <button
                  className='button'
                  onClick={e => {
                    this.add(x)
                  }}
                >{x}
                </button>
              </span>)}
          </div>

        </section>
        <footer className='modal-card-foot' onClick={this.props.closeModal}>
          <div className='buttons'>
            <button xxonClick={this.props.closeModal} className='button'>Cancel</button>
            <button onClick={this.save} className='button primary'>Save</button>
          </div>
        </footer>
      </div>
    )
  }

  add (name) {
    const newName = name || this.state.newName
    this.setState({
      networks: this.state.networks.concat(newName),
      newName: ''
    })
  }

  get user () {
    return this.props.client.user
  }
}
