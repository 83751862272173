/* eslint-env browser */
import React from 'react'

export function Account ({ cell, nav, link = true }) {
  // console.log('Account cell.getValue is', cell.getValue(), cell.getData())
  return <U node={cell.getValue()} link={link} nav={nav} />
}

export function U ({ node, link = true, focusId, setFocusId, focusLink, noFace, nav }) {
  if (nav) {
    if (!focusId) focusId = nav.focusId
    if (!setFocusId) setFocusId = nav.setFocusId
    if (!focusLink) focusLink = nav.focusLink
  }

  if (!node) {
    console.error('functionU node=%o', node)
    return <span style={{ background: 'red', border: '2px solid red' }}>null</span>
  }
  const id = node.id
  let n1 = id
  let n2 = ''

  if (typeof node.data !== 'function') {
    console.error('node is', node)
  }
  if (node.data('screenName')) {
    n1 = node.data('screenName')
  }

  for (const acct of node.data('accounts') || []) {
    if (acct.platform === 'local') {
      n2 += `user#${acct.id} `
    }
    if (acct.platform === 'twitter') {
      n1 = acct.name
      n2 += 'Twitter @' + acct.screen_name
    }
  }
  if (n1.length > 40) n1 = n1.slice(0, 37) + '...'

  const url = node.data('profileImageAt')
  let image = ''
  if (url && !noFace) {
    image = <Face url={url} size='1.4em' rounded />
  }

  const e = (<>{image}<strong>{n1}</strong>&nbsp;<small>{n2}</small></>)

  if (!link) return e

  // Caller should give us a focusLink, but that's pretty tedious. We
  // could also get it by calling useFocus, but that needs
  // visiparams. Of course, that's a global, but we don't want to rely
  // on that.  So instead we have this little hack.
  let href = '#focus=' + id
  if (focusLink) href = focusLink(id)

  return (
    <a
      style={{ textDecoration: 'inherit', color: 'inherit' }}
      href={href} onClick={e => {
        e.preventDefault()
        if (!setFocusId) alert('U needs setFocusId, nav=', JSON.stringify(nav))
        if (focusId === id) {
          if (setFocusId) setFocusId('')
        } else {
          if (setFocusId) setFocusId(id)
        }
      }}
    >
      {e}
    </a>
  )
}

export function accountSorter (a, b, aRow, bRow, column, dir, sorterParams) {
  let c = 0
  // console.log('accountSorter', {a, b, aRow, bRow, column, dir, sorterParams})
  const aNode = a // aRow.getData().node
  const bNode = b // bRow.getData().node
  if (!aNode && !bNode) return 0
  if (!aNode) return -1
  if (!bNode) return 1

  const aName = aNode.payload.screenName.toLowerCase()
  const bName = bNode.payload.screenName.toLowerCase()
  c = aName.localeCompare(bName, 'en-US')
  // console.log('names %o %o => %o then %o', aName, bName, c)
  return c
}

export function Face ({ url, size, rounded, alt = '' }) {
  const style = {
    verticalAlign: 'middle',
    marginRight: 3
  }
  if (size) {
    style.height = size
    style.width = size
  }
  if (rounded) {
    style.borderRadius = '50%'
  }
  return <img style={style} src={url} alt={alt} />
}
