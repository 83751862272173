import React from 'react'
import { pct, ago, Card, U, useFocus, useQuery, useClient } from './common'
import Multimap from 'multimap'

export default function Activity (props) {
  const [focusId, setFocusId, focusLink] = useFocus(props.params)
  const pp = Object.assign({ focusId, setFocusId, focusLink }, props)

  if (focusId) return <Focused {...pp} />
  return <Unfocused {...pp} />
}

// const q1 = client => client.edgeActivity()

const edgeZeroLastModified = (a, b) => {
  // [1] is the list of edges
  // [0] is the first edge
  // [2] is the edgePayload for that edge
  const at = a[1][0][2].lastModified || 0
  const bt = b[1][0][2].lastModified || 0
  // console.log('lastModified %o %o', a, b, at, bt)
  return bt - at
}

const q2 = client => {
  const m = new Multimap()
  for (const edge of client.edgeActivity()) {
    const [sid, tid] = edge
    m.set(sid, edge)
    m.set(tid, edge)
  }
  const r = []
  for (const key of m.keys()) {
    r.push([key, m.get(key)])
  }
  r.sort(edgeZeroLastModified)
  return r
}

function Unfocused ({ client, setFocusId, focusLink }) {
  const data = useQuery(client, q2)
  useClient(client) // because the names arriving don't update query results

  // Could get slow if this gets big.  Bring in paging, virtual dom,
  // filtering, or something.

  // Also, we might want a totally different format, like all the
  // scorings BY x or all the scorings of x, gathered together. In
  // that case, maybe there are tabs for time ranges.  Or maybe that's
  // a different tab.

  return (
    <div style={{ height: '100%' }} className='container is-fullhd'>
      {data.map(([id, edges]) => <Entry key={id} {...{ id, edges, client, setFocusId, focusLink }} />)}
    </div>
  )
}

function Entry ({ edges, client, id, setFocusId, focusLink, cut = 3 }) {
  const node = client.gg2.obtainNode(id)
  // eslint-disable-next-line no-unused-vars
  const [sid, tid, p] = edges[0]
  // const subject = client.gg2.obtainNode(sid)
  // const target = client.gg2.obtainNode(tid)

  const body = [] // does key={edge} work?
  let n = 0
  for (const edge of edges) {
    const [sid, tid, p] = edge
    const subject = client.gg2.obtainNode(sid)
    const target = client.gg2.obtainNode(tid)
    let agoPhrase = []
    if (edge.length > 1) agoPhrase = ago(p.lastModified) + ': '
    if (sid === id) {
      body.push(<em key={edge}>{agoPhrase}Gave a score of <strong>{pct(p.score)}</strong> to <U node={target} setFocusId={setFocusId} />, with reason {p.reason}</em>)
      n++
    } else if (tid === id) {
      body.push(<em key={edge}>{agoPhrase}Was scored <strong>{pct(p.score)}</strong> by <U node={subject} setFocusId={setFocusId} />, with reason {p.reason}</em>)
      n++
    } else {
      body.push(<span key={edge}>invalid entry</span>)
      console.error('unexpected entry in client.edgeActivity')
    }
    body.push(<br key={n} />) // HACK, use css instead please
    if (cut && n > cut) {
      body.push(<a key='extra' href={focusLink(id)} onClick={() => setFocusId(id)}>... {edges.length - n} more</a>)
      break
    }
  }

  return (
    <article className='media'>
      <figure className='media-left'>
        <p className='image is-64x64'>
          <img src={node.data('profileImageAt') || 'https://bulma.io/images/placeholders/128x128.png'} alt='profile' />
        </p>
      </figure>
      <div className='media-content'>
        <div className='content'>
          <p>
            <U node={node} noFace setFocusId={setFocusId} /> <small className='pl-3'>{ago(p.lastModified)}</small>
            <br />
            {body}
          </p>
        </div>
        {/*
    <nav className="level is-mobile">
      <div className="level-left">
        <a className="level-item">
          <span className="icon is-small"><i className="fas fa-reply"></i></span>
        </a>
        <a className="level-item">
          <span className="icon is-small"><i className="fas fa-retweet"></i></span>
        </a>
        <a className="level-item">
          <span className="icon is-small"><i className="fas fa-heart"></i></span>
        </a>
      </div>
    </nav>
      */}
      </div>
      {/*
  <div className="media-right">
    <button className="delete"></button>
  </div>
      */}
    </article>
  )
}

/*
// create the same structure as q2 but only for id.  silly.
const q3 = (client, id) => {
  console.log('x123 activity q3 id=', id)
  const edges = []
  for (const edge of client.edgeActivity()) {
    const [sid, tid, p] = edge
    if (sid === id || tid === id) edges.push(edge)
  }
  const r = [[id, edges]]
  return r
}

doesn't work
*/

// We PROBABLY want more detail about each entry?

function Focused ({ client, focusId, setFocusId, focusLink }) {
  let data = useQuery(client, q2)
  useClient(client) // because the names arriving don't update query results

  console.log('activity', data)
  data = data.filter(row => row[0] === focusId)
  if (data.length === 0) return <Card title='No available activity concerning this source' />

  const [id, edges] = data[0]
  return (
    <div style={{ height: '100%' }} className='container is-fullhd'>
      <Entry key={id} cut={false} {...{ id, edges, client, setFocusId, focusLink }} />
    </div>
  )
}
