const { EventEmitter } = require('eventemitter3')
// const { Graph, pct } = require('/home/sandro/Repos/osca-core')
const { Graph: GiantGraph,
  computeNodeScores
} = require('/home/sandro/Repos/giant-graph')
const rws = require('reconnecting-websocket')
// const equals = require('equals')
// const equal = require('fast-deep-equal')
const debug = require('debug')('osca-client')
const debug2 = require('debug')('osca-client/main')
const customError = require('custom-error')
// const visiparam = require('/home/sandro/Repos/visiparam')
const delay = require('delay')

// console.error('client init running')
// visiparam.number('Max Edges', { class: 'graph-controls', min: 0, max: 10000, default: 5 })
// visiparam.number('Max Nodes', { class: 'graph-controls', min: 0, max: 10000, default: 4 })
// visiparam.number('Node Min Hi', { class: 'graph-controls', min: 50, max: 100, default: 90 })
// debug2('visiparam._items: ', visiparam._items)

const TimeoutError = customError('TimeoutError')

// allow this package to be used from es6modules and node modules
const ReconnectingWebSocket = rws.default ? rws.default : rws
// debug2('RWS = %O %O', rws, ReconnectingWebSocket)

let counter = 0

// confidence is measured on a scale from 0.5 to 1.0 for ... reasons
// const confidence = score => score ? (0.5 + Math.abs(score - 0.5)) : 0.5

// For now we hardcode the list of nodes to merge. Next steps are
// probably either a way for users to validate the connection to their
// twitter account (with oauth or a special tweet or a DM), or
// processing of sameAs claims when they're sufficiently trusted.

const mergeList = []
const mergeMap = new Map()
const mergeListText = (
  `https://trustlamp.com/u/85 https://twitter.com/i/user/25805235 Robin
https://trustlamp.com/u/82 https://twitter.com/i/user/75123 SJ
https://trustlamp.com/u/84 https://twitter.com/i/user/10914642 Scott
https://trustlamp.com/u/53 https://twitter.com/i/user/23556190 Sandro`)
// http://localhost:8081/u/53 https://twitter.com/i/user/23556190 SandroTest`)
for (const line of mergeListText.split(/\n/)) {
  const [show, hide] = line.split(' ', 2)
  mergeList.push({ show, hide })
  mergeMap.set(hide, show)
}
const hiddenBehind = id => {
  return mergeMap.get(id)
}
const hiddenBehindOrSelf = id => {
  const n = mergeMap.get(id)
  if (n) return n
  return id
}

class Client extends EventEmitter {
  constructor (options = {}) {
    super()
    debug('Client() #%o constructed', ++counter)
    this.clientCounter = counter

    // debugging
    window.client = this

    // if you don't give us a busy() function, we'll make a null one,
    // which just returns an unbusy function that does nothing.
    this.busy = options.busy || (() => { return () => {} })

    this.Graph = GiantGraph // in case app wants to use subgraphs
    
    this.gg1 = new GiantGraph() // from server
    this.gg2 = new GiantGraph() // after local inference (merging, for now)
    this.extra = new Map()
    
    this.maintainNodeScores(this.gg1) // hack, no way to cancel
    this.showProgress(this.gg1) // hack, no way to cancel

    // we don't even use uses yet:
    // this.nodes = new Map() // id => payload
    // this.edges = new ArcMap() // sourceid => targetid => payload

    this.messageHandlers = {
      // 'RPC-response': this.onResponse.bind(this),
      // 'update': this.onUpdate.bind(this),
      // 'node-data': this.onNodeData.bind(this),
      // 'edge-data': this.onEdgeData.bind(this),
      'user-data': this.onUserData.bind(this),
      'message-to-user': this.onMessageToUser.bind(this),
      // 'invitation-created': () => {}
      // 'set-node': this.onSetNode.bind(this),
      // 'delete-node': this.onDeleteNode.bind(this),
      // 'set-edge': this.onSetEdge.bind(this),
      // 'delete-edge': this.onDeleteEdge.bind(this)
    }

    this.onOpen = this.onOpen.bind(this)
    this.onMessage = this.onMessage.bind(this)
    // this.graph = new Graph() // WARNING - visiparams release a dangerous clone
    this.url = options.url || 'ws://localhost:8081'
    // debug2('Using server at %o', this.url)
    this.ws = new ReconnectingWebSocket(this.url, [], {
      connectionTimeout: 10000,
      debug: false
    })
    this.ws.addEventListener('open', this.onOpen)
    this.ws.addEventListener('close', () => { this.onReset(); debug('ws close') })
    this.ws.addEventListener('message', this.onMessage)
    this.ws.addEventListener('error', e => { this.onReset(); debug('ws error', e) })
    this.loading = true
    // this.nodeMaxLow = 0.40
    // this.dirty = 0
    this.onReset()
    // this.pings = new Map()
    // this.pingCounter = 0
    // this.roots = []
    this.outstandingRPCs = new Map()
    this.rpcCounter = 1
    // this.visiparam = options.visiparam || visiparam
    // this.nodeMinHi = 0.60
    // this.minConfidence = 0.55

    this.paramChanges = 0
    // this.visiparam.id.maxEdges.on('change', n => { this.limit = n })
    this.start()
  }
  async close () {
    this.isOpen = false
    this.ws.close()
  }

  // Begin copy from giant-graph/limited-graph
  //
  // Should probably be moved to FrontGraph so we can use it.
  //
  // For now, just copying...

  async start () {
    debug('loop started')
    let myResolve
    while (!this.closed) {
      this.rebuildDone = new Promise(resolve => { myResolve = resolve })
      // debug('loop: pre-delay, this.closed=%o', this.closed)
      await delay(5)
      // debug('loop: post-delay')
      if (this.closed) break
      // debug('loop: pre-rebuild')
      await this._rebuild()
      // debug('loop: post-rebuild')
      myResolve() // note they wont start running until the delay() again
    }
    debug('loop terminated')
    if (myResolve) myResolve()
  }

  async rebuild () {
    if (this.rebuildNeeded()) {
      debug('someone waiting on rebuild')
      await this.rebuildDone
      debug('that person is free now')
    }
  }

  set limit (n) {
    if (typeof n !== 'number') throw Error('bad limit values')
    this._limit = n
    if (this.hardLimit === undefined) {
      // empirically, 1.5 seems to be about right. clearly faster than 1.2 or 1.7.
      this._hardLimit = Math.floor(n * 1.50)
    } else {
      this._hardLimit = this.hardLimit
    }
    if (!this.paramChanges) {
      debug('paramChanged++ because set limit')
    }
    this.paramChanges++
    debug('limit set to %o', n)
  }
  get limit () { return this._limit }

  rebuildNeeded () {
    return this.paramChanges > 0
  }

  async _rebuild () {
    if (!this.rebuildNeeded()) return

    if (this.isOpen) {
      this.paramChanges = 0
      await this.rpc('setCrawlGraph', {
        // root: this.gg1.roots,
        limit: this.limit
      })
    }
  }

  //
  // End copy / modify
  //

  send (...args) {
    this.ws.send(JSON.stringify(args))
  }
  rpc (...args) { // optional options, rpc function name, ...rpc params
    let options = {}
    if (typeof args[0] !== 'string') options = args.shift()
    const op = args.shift()
    const params = args

    const seq = this.rpcCounter++
    const unbusy = this.busy('Waiting for server operation: ' + op)
    const clear = () => {
      this.outstandingRPCs.delete(seq)
      unbusy()
    }
    const rec = { seq, clear }
    rec.promise = new Promise((resolve, reject) => {
      rec.resolve = resolve
      rec.reject = reject
    })
    if (options.timeout) {
      rec.timeoutHandle = setTimeout(() => {
        const msg = `RPC call time out, op=${op} url=${this.url}`
        console.error(msg)
        rec.reject(TimeoutError(msg))
        clear()
      }, options.timeout)
    }
    this.outstandingRPCs.set(seq, rec)
    this.send(seq, op, ...params)

    return rec.promise
  }
  /*
  showBusy () {
    if (!this.spinner) return
    if (this.computing || this.outstandingRPCs.size > 0) {
      this.spinner.style.display = 'block'
      debug2('spin? computing = %o, size = %o', this.computing, this.outstandingRPCs.size, 'spinning', Date.now())
    } else {
      this.spinner.style.display = 'none'
      debug2('spin? computing = %o, size = %o', this.computing, this.outstandingRPCs.size, 'stop', Date.now())
    }
  }
  */
  onResponse (seq, err, data) {
    const rec = this.outstandingRPCs.get(seq)
    if (!rec) {
      console.warn('server sent extra RPC response, seq =', seq)
      return
    }

    this.outstandingRPCs.delete(seq)

    rec.clear()
    if (err) {
      rec.reject(err)
    } else {
      rec.resolve(data)
    }
  }

  // crude, but probably fine. Only needed for testing right now anyway.
  async RPCsDone () {
    while (true) {
      if (this.outstandingRPCs.size === 0) return
      await delay(1)
    }
  }

  onReset () {
    // this.watchingNodes = new Set()
    if (this.timerId) {
      clearInterval(this.timerId)
      delete this.timerId
    }
  }
  onOpen () {
    debug('ws open')
    this.isOpen = true

    this.gg1.silentClear()
    this.gg1.emit('clear') // we don't want to be notified about each change

    this.resumeSession() // don't need to wait for response

    /*

    // crude debouncer, for now, so we don't re-score & re-layout
    // after each little change we get.
    this.timerId = setInterval(() => {
      if (this.dirty) {
        debug('db dirty %o', this.dirty)
        this.dirty = 0
        this.processChanges()
      }
    }, 100)
    if (this.timerId.unref) this.timerId.unref()

    */

    // I don't think we need this -- the server will just send stuff.
    // this.paramChanges++

    // this.rpc('setMaxEdges', this.visiparam.data('maxEdges'))
    // this.watchBestNodes() // maybe put this in reset?  queue them up then?
  }
  /*
  watchBestNodes () {
    // sort them by confidence & cutoff an some visiparam limit?

    // actually we watch all of them, but we only ask for arcs
    // if we're okay with there being more
    // this.maxNodes = visiparam.data('maxNodes')
    // this.nodeMinHi = visiparam.data('nodeMinHi') / 100
    // debug2('watchBestNodes() %O', {maxNodes: this.maxNodes, nodeMinHi: this.nodeMinHi})

    /*
    let counter = 0, wantEdges = true
    for (const node of this.graph.bestNodes({cutoff: 0, limit: 1000})) {
      if (counter++ > this.maxNodes) wantEdges = false
      // debug2('watching node: %O', node.json())
      this.watchNode(node, wantEdges)
    }
    * /

    for (const node of this.graph.cy.nodes()) this.watchNode(node, true)
  }
  async watchNode (node, wantEdges) {
    node = this.graph.obtainNode(node)
    const nodeid = node.id()
    const nodeScore = node.data('simpleScore')
    debug('watchNode %o, ss %o', nodeid, nodeScore)

    if (confidence(nodeScore) < this.minConfidence) {
      debug('.. too low confidence to watch %O', node.json())
      node.data('tooLowToAskServer', true)
      return node
    }
    node.data('tooLowToAskServer', false)

    const now = {nodeScore}
    const was = node.data('watching')
    if (equals(was, now)) {
      debug('.. was already watching, same params')
      return node
    }
    node.data('watching', now)

    const params = { nodeid, priority: nodeScore }
    debug('+watchNode %o rpc', params)
    await this.rpc('setSourcePriority', params)
    debug('-watchNode %o rpc', params)
    return node
  }
  onUpdate (items) {
    debug('onUpdate with %o items', items.length)
    for (const item of items) {
      let ele
      if (item.id) {
        ele = this.onNodeData(item.id, item)
      } else if (item.source) {
        // ele = this.onEdgeData(item.source, item.target, item)
      } else {
        debug2('bad update, all items = %O', items)
        debug2('bad update, bad item = %O', item)
        throw Error('bad update from server')
      }
      ele.data('hasDataFromServer', true)
      debug('.. updated %o %o', ele.group(), ele.id())
    }
    this.dirty++
  }
  */
  onMessage (m) {
    // debug2('ws message %O', m)
    const [op, ...args] = JSON.parse(m.data)

    if (typeof op === 'number') {
      if (op >= 0) {
        if (op > 0) console.error('client doesnt implement returns')
        const method = args.shift()
        const methodName = 'exposed_' + method
        if (typeof this[methodName] === 'function') {
          this[methodName](...args)
        } else {
          console.error('unimplemented RPC method from server: %o', method)
        }
      } else {
        this.onResponse(Math.abs(op), args[0], args[1])
      }
    } else {
      const h = this.messageHandlers[op]
      if (h) {
        // debug('calling h with args %O', args)
        // debug2('calling %o with args %O', h, args)
        h(...args)
      } else {
        console.error('unknown op code from server: %o', op)
      }
      if (op === 'node-data' || op === 'edge-data') this.dirty++
    }
  }
  /*
  onNodeData (id, data) {
    // debug('onNodeData got %o %O', id, data)

    const create = this.graph.cy.nodes().size() < this.maxNodes

    const node = this.graph.obtainNode(id, create)
    if (!node) return null

    for (const [key, value] of Object.entries(data)) {
      node.data(key, value)
    }
    // maybe set some kind of dirty class on the node, that it needs
    // layout and scoring, when others might not, so we can be more
    // incremental?
    return node // sometimes called without event
  }
  /*
  onEdgeData (sourceid, targetid, data) {
    const source = this.graph.obtainNode(sourceid)
    const target = this.graph.obtainNode(targetid)
    const edge = this.graph.setEdge(source, target, data.score, data.prov)

    const oldSS = target.data('simpleScore')
    const sourceSS = source.data('simpleScore') || 0
    const edgeScore = data.score || 0
    const newSS = sourceSS * edgeScore
    if (confidence(newSS) > confidence(oldSS)) {
      target.data('simpleScore', newSS)
    }
    this.watchNode(target) // does its own filtering

    // -- these make debugging hard
    // delete data.score
    // delete data.prov
    for (const [key, value] of Object.entries(data)) {
      if (key === 'score') continue
      if (key === 'prov') continue
      edge.data(key, value)
    }
    return edge
  }
  * /
  processChanges () {
    debug('processChanges() running, ******* NOT *********scoring')

    // this.graph.updateNodeScores()

    const cy = this.graph.cy
    for (const n of cy.nodes()) {
      /*
      let name = n.data('msg')
      const tw = n.data('twitterAccount')
      if (tw) {
        name = tw.screen_name + ' ' + pct(n.data('score'))
      }
      * /
      if (n.data('screenName')) {
        n.data('msg', n.data('screenName') + ' ' + pct(n.data('score')))
      }
    }
    this.emit('change')
    debug('processChanges emit change', cy.nodes().size(), cy.edges().size())
    this.graph.runLayout() // maybe just a 'minor' layout??
    // this.watchBestNodes()
  }
  */

  /**
     returns promised of { elapsed }, the time taken in ms

     Maybe it'll include other server info as well?

     add a timeout?  how could this fail without a connection error or
     a server bug?
  */
  async ping (param) {
    const d = {}
    d.start = Date.now()
    d.fromServer = await this.rpc('ping', param)
    d.end = Date.now()
    d.elapsed = d.end - d.start
    return d
  }

  setRoot (root) {
    console.error('best not call setRoot - the server uses the login')
    if (this.gg1.setRoot(root)) this.paramsChanged()
  }

  // deprecated:
  setRoots (...roots) {
    this.setRoot(roots)
  }

  /*
  elements (options) {
    if (this.graph && this.roots.length) {
      // debug2('elements: options = %o', options)
      const nodes = this.graph.bestNodes(options)
      debug2('db.elements returning best %o nodes', nodes.length)
      return nodes
    } else {
      return []
    }
  }
  nodeData (id) {
    const node = this.graph.cy.getElementById(id)
    // debug2('db.nodeData(%o) returning %O', id, node && node.json())
    return node && node.json()
  }
  */

  onUserData (user) {
    // console.log('onUserData %o', user)
    if (user) {
      debug('onUserData %o', user)
      this.user = Object.assign({}, user)
    } else {
      this.user = null
    }
    this.emit('change-user-data', this.user)
    if (user !== this.wasUser) {
      if (user && !this.wasUser) this.emit('login', user)
      if (!user && this.wasUser) this.emit('logout', this.wasUser)
      this.wasUser = user
    }
  }

  onMessageToUser (html) {
    // what's our preferred toast library?    do we .emit this or what?
    console.error('MESSAGE-TO-USER', html)
  }

  /*
  async signup (userData) {
    const user = await this.rpc('signup', userData)
    this.onUserData(user)
    debug2('login returned %O', user)
    return user
  }
  */
  
  async login (userData) {
    const result = await this.rpc('login2', userData)
    // console.warn('login result', result)
    const {token, user} = result
    this.onUserData(user)
    debug2('login returned %O', user)

    window.localStorage.sessionId = user.id
    window.localStorage.sessionToken = token
    debug('set sessionToken', token)
    
    return user
  }
  async logout () {
    delete window.localStorage.sessionId
    delete window.localStorage.sessionToken
    this.user = null
    await this.rpc('logout')

    // this.emit('change-user-data', this.user)
    // this.emit('logout')

    // Until client is smarter about this situation
    document.location.reload()
  }
  async setUserData (userData) {
    if (!userData) userData = this.user
    await this.rpc('setUserData', userData)
    // doesn't return anything, because it comes back to all
    // connections as a user data change. This is an RPC so
    // that we can get an error report if there is one, and
    // know when it's expected to have been completed.

    // THIS should come back via the net; we don't need to do it.
    // this.emit('change-user-data', this.user)
  }

  async findInvitation (text) {
    return this.rpc('findInvitation', text)
  }
  get siteurl () {
    return document.location.origin // + document.location.pathname
  }
  async createInvitation (aboutInvited) {
    // With the current implementation, we could do this locally, but
    // let's leave it to the server to potentially change the format.
    return this.rpc('createInvitation', { aboutInvited, siteurl: this.siteurl })
    // result also shows up as a change to the list of users we've invited
  }
  async acceptInvitation (text) {
    return this.login({ invitationCode: text })
  }
  // returns a promise of the next user data we receive
  nextUserData () {
    return new Promise(resolve => {
      this.once('change-user-data', u => {
        debug('nextUserData resolving with', u)
        resolve(u)
      })
    })
  }
  async resumeSession () {
    const id = window.localStorage.sessionId
    const token = window.localStorage.sessionToken
    debug('resumeSessions %o', { id, token })
    debug2('resumeSessions %o', { id, token })
    // debug2('localstorage: %O', window.localStorage)
    if (token && id !== undefined) {
      debug('... trying to resume')
      debug2('Trying to resume session with id %o', id)
      try {
        const user = await this.login({ id, token })
        debug2('resumed session for user: %o', user.id)
      } catch (e) {
        debug2('resume failed: %o', e)
      }
    }
  }
  syncSearch (text) {
    text = text.toLowerCase()
    const res = new Set()
    for (const node of this.gg1.loadedNodes.values()) {
      // debug2('candidate node: %O', node)
      // debug2('... json: %O', node.json())
      for (const field of ['profileAt', 'screenName']) {
        const value = node.data(field)
        if (value && value.toLowerCase().indexOf(text) >= 0) {
          res.add(node)
          break
        }
      }
      if (res.size > 40) break
    }
    return [...res.values()]
  }
  async asyncSearch (prev, text) {
    const res = new Set(prev)
    const fromServer = await this.rpc('nodeSearch', text)

    // debug2('nodeSearch from server %O', fromServer)
    // do we add these to the graph...?  if not, we can't return cy nodes
    //
    // do we at least add the focus?
    //
    // I guess add them for now...   maybe with a class?

    if (fromServer) {
      for (const obj of fromServer) {
        // const was = this.gg1.obtainNode(obj.id)
        // const node = this.onNodeData(obj.id, obj) // might as well update
        // res.add(node)
        // if (!was) node.addClass('searchResult') // so we can remove?

        const node = this.gg1.obtainNode(obj.id, obj)
        res.add(node)
      }
    }
    return [...res.values()]
  }

  getUserEdge (target) {
    if (!this.user.edges) this.user.edges = []

    for (const edge of this.user.edges) {
      if (edge.target === target) return edge
    }
    return null
  }

  /**
     Overlay new properties on the edgePayload between the currently
     logged in user and edge.target

     - Should allow source to be something we control, not just us?
     - Should allow more claims orientation?

   */
  async setUserEdge (edge) {
    debug('setUserEdge %o', edge)
    let curr = this.getUserEdge(edge.target)
    if (!curr) curr = {}

    Object.assign(curr, edge)
    // debug2('setUserEdge 2 %o', curr)

    this.user.edges = this.user.edges.filter(e => e.target !== edge.target)

    // clean up any bad edges?
    // this.user.edges = this.user.edges.filter(e => e.score || e.prov || e.reason || e.request )

    curr.lastModified = Date.now()
    if (!edge.delete) this.user.edges.push(curr)

    debug('setUserEdge after modification edges=%O', this.user.edges)
    await this.setUserData()
    // should get change events from server in response
  }

  /*
  exposed_setNode (id, payload) {
    debug('setNode', id, payload)
    this.onNodeData(id, payload)
    this.emit('set-node', id, payload)
    this.nodes.set(id, payload)
    this.dirty++
  }
  exposed_deleteNode (id) {
    debug('deleteNode', id)
    this.emit('delete-node', id)
    debug2('ignoring delete-node')
    this.nodes.delete(id)
  }

  exposed_setEdge (sourceid, targetid, payload) {
    debug('setEdge')
    // this is going to create a ton of target nodes in cy which we don't know
    // anything about, and if the edge goes away, we need to make them go away
    // somehow...

    this.emit('set-edge', sourceid, targetid, payload)

    const source = this.graph.obtainNode(sourceid)
    const target = this.graph.obtainNode(targetid)
    const edge = this.graph.setEdge(source, target, payload.score, payload.prov)

    const oldSS = target.data('simpleScore')
    const sourceSS = source.data('simpleScore') || 0
    const edgeScore = payload.score || 0
    const newSS = sourceSS * edgeScore
    if (confidence(newSS) > confidence(oldSS)) {
      target.data('simpleScore', newSS)
    }
    this.watchNode(target) // does its own filtering

    // -- these make debugging hard
    // delete data.score
    // delete data.prov
    for (const [key, value] of Object.entries(payload)) {
      if (key === 'score') continue
      if (key === 'prov') continue
      edge.data(key, value)
    }
    this.dirty++
    // debug2('set edge %o %o %o', sourceid, targetid, payload)
  }
  exposed_deleteEdge (sourceid, targetid, payload) {
    this.emit('delete-edge', sourceid, targetid, payload)
    this.graph.setEdge(sourceid, targetid, 'none')
    // debug2('deleted edge %o %o', sourceid, targetid)
    this.dirty++
  }

  */

  // eslint-disable-next-line camelcase
  exposed_e (sourceid, targetid, edgePayload) {
    debug('got edge %o %o %o', sourceid, targetid, edgePayload)

    // The server sometimes sends edges before the node payload is
    // asynchronously available. Hopefully a node will only be in that
    // state for a very short time. Like, the users's shouldn't be
    // seeing nodes like this, with no screenName, etc.
    if (!this.gg1.loadedNodes.has(sourceid)) {
      this.gg1.obtainNode(sourceid, { placeholderPayload: true })
      // throw Error('server sent edge without sending source node first, id = ' + sourceid)
    }
    if (!this.gg1.loadedNodes.has(targetid)) {
      this.gg1.obtainNode(targetid, { placeholderPayload: true })
      // throw Error('server sent edge without sending target node first, id = ' + targetid)
    }

    this.gg1.setEdgePayload(sourceid, targetid, edgePayload)
  }

  // eslint-disable-next-line camelcase
  exposed_n (nodeid, payload) {
    debug('got node %o %o', nodeid, payload)
    this.gg1.setNodePayload(nodeid, payload)
  }

  async maintainNodeScores () {
    let dirty = 0
    this.gg1.on('setEdgePayload', () => { dirty++ })
    this.gg1.on('setNodePayload', () => { dirty++ })
    let unbusy
    while (true) {
      if (this.user && (dirty || this.extrasDirty)) {
        dirty = 0
        this.extrasDirty = false
        debug2('recomputing scores')
        if (unbusy) unbusy()
        unbusy = this.busy('computing scores on modified graph')
        await delay(1) // give it time to show, since we're compute-bound

        // oh, also, if we got dirty in that 1ms, let's restart, because
        // there's no point in computing while stuff is flowing in
        if (dirty) continue
        this.gg1.setRoot(this.user.nodeid)
        const t0 = Date.now()
        debug2('----- computing, dirty = %o', dirty)

        const temp = new this.Graph()
        temp.copyFrom(this.gg1)
        this.addExtrasTo(temp)
        
        this.gg2.silentClear()
        this.copyWhileMerging(temp, this.gg2)
        const scores = await computeNodeScores(this.gg2)
        debug2('----- compute DONE, dirty = %o', dirty)
        // let extraNodes = new Set(this.gg2.loadedNodes.values())
        for (const node of this.gg2.nodes()) delete node.payload.score
        debug('new scores %O, %oms', scores, Date.now() - t0)
        for (const [id, score] of scores) {
          /*
          const payload = await this.gg2.getNodePayload(id)
          const pt = typeof payload
          debug('id=%o score=%o pt=%o, payload=%o', id, score, pt, payload)
          if (pt !== 'object') {
            console.error('wrong payload type (' + pt + ') node ' + id)
          }
          payload.score = score
          */
          const node = this.gg2.obtainNode(id, {})
          // extraNodes.delete(node)
          node.payload.score = score // or node.score ?!
        }

        /* We used to remove unreachable nodes, but the server doesn't
         * send them again since it thinks we still have them, and we
         * probably SHOULD hold on to them in case they become
         * reachable again */
        // for (const node of extraNodes) this.gg2.setNodePayload(node, false)

        this.gg2.deleteEdgesToMissingTargets()
        this.gg2.setIns()
        debug2('scoring done after %oms', Date.now() - t0)
        debug2('scored nodes =  %O', [...this.gg2.nodes()])
        this.emit('change')
        debug2('change pushed after total %oms', Date.now() - t0)
        unbusy()
      }
      await delay(10)
    }
  }
  async showProgress () {
    let c = 0
    while (true) {
      const n = this.gg1.edgeCount()
      if (n !== c) debug2('%o edges added', n - c)
      c = n
      const dom = document.getElementById('edgeCount')
      if (dom) {
        dom.innerText = n
      }
      await delay(100)
    }
  }
  edgeActivity () {
    const res = []
    for (const [s, t, p] of this.gg2.edges()) {
      if (p.prov === 'manually set') res.push([s, t, p])
    }
    res.sort(lastModified)
    return res
  }
  convertToAccountsList (payload) {
    payload.accounts = []
    let a

    a = payload.twitterAccount
    if (a) {
      a.platform = 'twitter'
      payload.accounts.push(a)
      delete payload.twitterAccount
    }

    a = payload.localAccount
    if (a) {
      a.platform = 'local'
      payload.accounts.push(a)
      delete payload.localAccount
    }
  }

  /**
     Copy g1 to g2, while implementing the merges
  */
  copyWhileMerging (g1, g2) {
    const t0 = Date.now()
    const toMerge = []
    for (const node of g1.nodes()) {
      // this can happen if the node is partially downloaded
      if (node.payload === true || typeof node.payload === 'string') continue

      const show = hiddenBehind(node.id)
      if (show) {
        toMerge.push({ show, hide: node.id })
      } else {
        // maybe we should do a system-wide conversion to this style
        // of having a .accounts list, but for now we just do it
        // here, at gg1->gg2 time
        const payload = Object.assign({}, node.payload)
        this.convertToAccountsList(payload)
        g2.setNodePayload(node.id, payload)
      }
    }
    for (const { show, hide } of toMerge) {
      const shown = g2.obtainNode(show, false)
      const hidden = g1.obtainNode(hide, false)
      if (!shown || !hidden) continue // not both in graph currently

      // merge payloads  ? mutate or set?  I think mutate is okay
      const hp = Object.assign({}, hidden.payload)
      this.convertToAccountsList(hp)
      shown.payload.accounts.push(...hp.accounts)

      // not sure if we're going to use this, but maybe
      if (!shown.payload.hiding) shown.payload.hiding = {}
      shown.payload.hiding[hide] = hp
    }

    // now copy & rewrite the edges
    for (const [s, t, p] of g1.edges()) {
      const s2 = hiddenBehindOrSelf(s)
      const t2 = hiddenBehindOrSelf(t)
      let payload = p
      const altP = g2.getEdgePayload(s2, t2, {})
      if (altP) {
        // which edge payload do we keep?
        // console.error('Which payload to keep?')
        // debug2(s, t, p)
        // debug2(s2, t2, altP)

        // Hm.  If this is an edge where either end is hidden, and
        // there's a different payload available, let's use the other
        // payload.  Not great in the situation where both ends are
        // re-written.
        if (s !== s2) continue
        if (t !== t2) continue
      }
      g2.setEdgePayload(s2, t2, payload)
    }

    g2.roots = g1.roots.map(hiddenBehindOrSelf)

    debug2('copyWhileMerging ran in %oms', Date.now() - t0)
  }

  // should this be in gg instead, passed f = n => n.score
  //
  // it's bestPath for f = mtr   (pick the "best" in)
  //
  //
  mtrPath (dst) {
    const root = this.gg2.obtainNode(this.gg2.roots[0])
    let out = []
    let here = dst
    let n = 10
    console.log('mtrPath from %o to %o', root.id, dst.id)
    while (here !== root) {
      console.log('.. incoming to %o', dst.id)
      if (n-- === 0) throw Error('mtrPath looping')
      const ins = [...here.ins]
      ins.sort((a, b) => {
        // a and b are each: [source, edgePayload]
        return b[0].score - a[0].score
      })
      console.log('.. options %O', ins.map(([s]) => s.id), ins)
      const [src, pay] = ins[0]
      out.unshift([src, here, pay])
      here = src
    }
    return out
  }

  async loadExtraNode (id) {
    const data = await this.rpc('fetchNode', {id, maxOuts:20})
    console.warn('got extra', data)
    this.extra.set(id, data)
    this.extrasDirty = true
  }

  async addExtrasTo (gg) {
    console.log('client.addExtrasTo')
    for (const events of this.extra.values()) {
      console.log('... client.addExtrasTo events=', events)
      for (const [op, ...args] of events) {
        if (op === 'e') {
          console.log('client.addExtrasTo, edge:', ...args)
          gg.setEdgePayload(...args)
        } else {
          gg.setNodePayload(...args)
        }
      }
    }
  }

}

function lastModified (a, b) {
  const at = a[2].lastModified || 0
  const bt = b[2].lastModified || 0
  // debug2('lastModified %o %o', a, b, at, bt)
  return bt - at
}

// need to know who to connect to....
//
// const client = new Client()

module.exports = { Client }
