import React from 'react'
import 'bulma/css/bulma.css'
import './more.css'
import Navbar, { Tabs } from './Navbar'
import { useUser } from './User'
import Splash from './Splash'
import shortcuts from './shortcuts'
import backArrow from './back-arrow-80.png'
// import { Busy } from './busy'

import { useNav } from './useNav'

import components from './globalComponents'

const tabs = ['Reports', 'Notes', 'Explore', 'Activity']

// let renderCount = 0

export default function App ({ client, params }) {
  const nav = useNav({ params, components })
  const user = useUser(client)

  shortcuts.Escape = nav.close
  shortcuts.ArrowLeft = () => nav.switchTab(tabs, -1)
  shortcuts.ArrowRight = () => nav.switchTab(tabs, 1)

  let { modalName } = nav
  const { pageName, closeModal, linkForModal, parentPage, linkForPage } = nav
  const props = { client, params, nav, closeModal }

  const ic = params.data('invitationCode')
  if (ic && !user && !modalName) {
    // logout if different user?
    
    nav.openModal('UseInvitation')
    // and set for now?!
    modalName = 'UseInvitation'
  }

  nav.titleHeight = '3rem'
  nav.headerHeight = `calc(${nav.titleHeight} + 2.5rem`
  nav.footerHeight = '3rem'

  nav.lowerPaneHeight = '25vh'
  nav.lowerPaneBottom = nav.footerHeight
  nav.lowerPaneTop = `calc(100vh - (${nav.lowerPaneHeight} + ${nav.lowerPaneBottom}))`

  nav.centerHeight = `calc(100vh - (${nav.headerHeight} + ${nav.footerHeight})`

  nav.upperPaneHeight = `calc(${nav.centerHeight} - ${nav.lowerPaneHeight})`
  nav.upperPaneTop = nav.headerHeight
  nav.upperPaneBottom = `calc(${nav.lowerPaneHeight} + ${nav.lowerPaneBottom})`

  nav.legal = (
    <p style={{ textAlign: 'center' }}>
      <a style={{ padding: '0.5rem' }} href={linkForModal('Feedback')}>Feedback</a>
      |
      <a style={{ padding: '0.5rem' }} href='https://hawkeworks.com/privacy'>Privacy Policy</a>
      |
      <a style={{ padding: '0.5rem' }} href='https://hawkeworks.com/terms'>Terms</a>
    </p>
  )

  // style={{backgroundColor: 'rgba(200,200,200,0.85)'}}
  let modalOverlay = ''
  if ((user && modalName) || modalName === 'About' || modalName === 'User' || modalName === 'UseInvitation') {
    // console.log('modal overlay set for', modalName)
    modalOverlay = (
      <aside className='modal is-active'>
        <div className='modal-background' onClick={closeModal} />
        <div className='modal-content' style={{ background: 'white' }}>
          {React.createElement(nav.componentByName(modalName), props)}
        </div>
        <button onClick={nav.closeModal} className='modal-close is-large' aria-label='close' />
      </aside>
    )
  } else {
    // console.log('App rendering, no modal overlay')
  }

  let page = <Splash {...props} />
  if (user && pageName) {
    page = React.createElement(nav.componentByName(pageName), props)
  }
  console.log('App rendering', { pageName, modalName })

  let back = ''
  if (user && parentPage) {
    // No idea why we need this onClick. The href should be trapped like it usually
    // is, but for some reason, it's causing page reloads here
    console.log('parentPage', { parentPage, link: linkForPage(parentPage) })
    back = <a onClick={e => { nav.openPage(parentPage); e.preventDefault() }} href={linkForPage(parentPage)} style={{ margin: '0.5rem', height: '2.5rem', width: '2.5rem' }} classNames='button is-medium'><img src={backArrow} alt='up' /></a>
  }

  return (
    <div className='App'>
      {modalOverlay}
      <Navbar {...{ pageName, back, ...props }} />
      <div style={{ position: 'fixed', top: '3rem', bottom: '4.5rem', left: 0, right: 0, overflow: 'auto' }}>
        {page}
      </div>
      <footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: -1, background: 'white' }}>
        {user && <Tabs tabs={tabs} pageName={pageName} {...props} />}
        {user ? '' : nav.legal}
      </footer>
    </div>
  )
}
