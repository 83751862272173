/*
  === this text was written before the README and may be out of date ===

  This is a nodes-and-edges graph with a balance of features:

  - every operation is pretty fast. Aim is to work with millions of
    edges in a browser.

  - each node and each edge has a "payload" which is some truthy
    value, typically an application object. Some functions require it
    be an object to which we can add our own properties.  Nodes have
    an .id which is some truth value (typically a string or number).

  - all edges are directed (aka arcs), and no two edges have the same
    [sourceid, targetid]. We don't give edges ids (as done in
    cytoscape, graphogramy, or ngraph). Instead you identify an edge
    by the source or sourceid and targetid.

  - in general, you can only traverse the graph in a forward
    direction. By default, nodes do not store their incoming
    edges. The graph has a set of zero or more roots. Edges can have
    targetids which are not even nodes in the graph (but might be
    materalized later).

  - setting the payload of an edge or node to falsy (false, null, 0,
    undefined, '', NaN) deletes it. Nodes may be only partially
    deleted until a linear flush().  Need to see if targetid is is in
    this.loadedNodes.

  - changing edges or nodes results in emitting events:
      this.emit('setNodePayload', id, payload)    falsy payload == delete
      this.emit('setEdgePayload', s, t, payload)  falsy payload == delete

  - a graph can have .inputs, which are other objects implementing the
    same interface, in which case we watch/mirror it. This is
    typically for subclassing. Behavior is undefined if more than one
    input uses the same nodeid; they should have disjoint id spaces.

  - this graph may be only a subgraph of the union of the inputs (and
    it might diverge in other ways if the caller calles .set[]Payload
    directly.)  The idea is to obtain bits of the inputs on demand,
    using two async methods for subclasses:

       async loadNode (id) -> node
       async loadOuts (node) -> node with .outs defined (Map targetid -> payload)

*/

const EventEmitter = require('eventemitter3')
const Heap = require('mnemonist/heap')
const equal = require('fast-deep-equal')
// const debug = require('debug')('giant-graph:Graph')
const dbg = require('debug')
const delay = require('delay')
const deepcopy = require('deepcopy')

// used to hide from JSON.stringify, so we dont look cyclic
// const myGraph = Symbol('myGraph')

class Node {
  //    .graph[? ug loop hide with symbol?]    .outs   .payload  .ins sometimes
  //
  //    OR it IS the payload, forcing payload to be object we can mangle?
  //    No. that doesnt stack very well, unless we use Symbols
  //
  //  node[g.outsSymbol]
  constructor (options) {
    Object.assign(this, options)
    if (!this.payload) throw Error('Nodes must have a truthy payload')
    if (!this.id) throw Error('Nodes must have a truthy id')

    if (!this.outs) this.outs = new Map() // targetid -> edgePayload
    // this.outsLoaded = false    [implied]
  }
  * outEdges () {
    for (const [targetid, payload] of this.outs) {
      yield [ this.id, targetid, payload ]
    }
  }
  * outObjectEdges () {
    for (const [targetid, payload] of this.outs) {
      // we give the source, not sourceid, which might leak node objects
      // between graphs, but saves some lookups, so ehhhh.
      yield [ this, targetid, payload ]
    }
  }
  data (key, value) {
    if (value === undefined) {
      return this.payload[key]
    } else {
      this.payload[key] = value
      return null
    }
  }
}

let graphCounter = 0

/**
 * Class representing a directed graph.
 *
 * @extends EventEmitter
 */
class Graph extends EventEmitter {
  constructor (options) {
    super()

    this.debug = dbg('Graph-' + graphCounter++)
    // We call our map of nodes "loadedNodes" thinking we might be a front.
    // If we just called in "nodes" that might be a bit misleading sometimes.
    this.loadedNodes = new Map() // id -> Node
    this.onClose = []
    this.roots = []
 
    this.debug('constructor options: %o', options)
    Object.assign(this, options)

    if (this.root) {
      this.roots = [this.root]
      delete this.root
    }
  }

  close () {
    this.onClose.map(x => x())
    this.closed = true
  }

  hasNodeId (id) {
    return !!this.loadedNodes.get(id)
  }

  nodeId (x) {
    if (typeof x === 'string') return x
    return x.id
  }

  setRoot (root) {
    let roots
    if (Array.isArray(root)) {
      roots = root
    } else {
      roots = [root]
    }
    for (const r of roots) {
      if (typeof r !== 'string') {
        throw RangeError('invalid value for setRoot: ' + JSON.stringify(r))
      }
    }
    if (!equal(this.roots, roots)) {
      this.roots = roots
      this.logParamChange('set root')
      return true
    }
    return false
  }

  obtainNode (id, payloadForCreation) {
    if (typeof id === 'object') return id

    // 1-slot memo for performance ?
    // if (id === this.memo_obtainNodeIn) return this.memo_obtainNodeOut

    let node = this.loadedNodes.get(id)
    if (!node) {
      if (payloadForCreation) {
        node = new Node({
          // [myGraph]: this,
          id,
          payload: payloadForCreation
        })
        this.debug('obtainNode created %o', node)
        if (payloadForCreation === true) console.error('WTF', node)
        this.loadedNodes.set(id, node)
        this.emit('setNodePayload', id, payloadForCreation)
      } else {
        return null
      }
    }
    // this.memo_obtainNodeIn = id
    // this.memo_obtainNodeOut = node
    return node
  }
  setNodePayload (id, payload) {
    const node = this.obtainNode(id, payload)
    node.payload = payload 
    if (!payload) {
      this.loadedNodes.delete(node.id)
      // leave it as a tombstone for anyone with pointer
      node.deleted = true
      // maybe easier GC?
      delete node.outs
      delete node.ins
      // console.log('snp deleted node %o', node)
    }
    this.emit('setNodePayload', id, payload)
    return node
  }
  setEdgePayload (s, t, p) {
    s = this.obtainNode(s, 'flag that payload is missing') // hack sorry.
    const targetid = t.id ? t.id : t
    if (p) {
      s.outs.set(targetid, p)
    } else {
      s.outs.delete(targetid)
    }
    this.emit('setEdgePayload', s.id, targetid, p)
  }
  getEdgePayload (s, t, sourcePayloadForCreate) {
    s = this.obtainNode(s, sourcePayloadForCreate)
    if (!s) return null
    const targetid = t.id ? t.id : t
    return s.outs.get(targetid)
  }

  deleteEdgesToMissingTargets () {  // aka flush()
    for (const source of this.loadedNodes.values()) {
      if (!source.outs) console.error('node without outs, %O', source)
      for (const targetid of source.outs.keys()) {
        const target = this.loadedNodes.get(targetid)
        if (!target) source.outs.delete(targetid) // emit???
      }
    }
  }
  edgeCount () {
    let count = 0
    for (const source of this.loadedNodes.values()) {
      // eslint-disable-next-line no-unused-vars
      for (const targetid of source.outs.keys()) {
        // SHOULD WE only count edges to targets that exist?
        // const target = this.loadedNodes.get(targetid)
        // if (target) count++
        count++
      }
    }
    return count
  }

  // async in case it runs a long time
  async walkBestFirst (visitor, edgeCompare) {
    const heap = new Heap(edgeCompare)

    for (const node of this.loadedNodes.values()) delete node.visited

    if (this.roots.length > 1) throw Error('multiple roots not implemented')
    // this.debug('ROOTS = %o', this.roots)
    let target = this.obtainNode(this.roots[0], { from: 1 })
    let edge = null
    let steps = 0
    while (true) {
      // 1000 feels smoother, although it's like 10% more elapsed time than 3000
      if (++steps % 1000 === 0) await delay(1)
      this.debug('bestFirst, targetid = %o', target.id)
      if (!target.visited) {
        visitor(target, edge) // edge is the edge we used to get there
        target.visited = true
        for (const edge of target.outObjectEdges()) heap.push(edge)
      }
      edge = heap.pop()
      this.debug('popped %o', edge)
      if (!edge) break
      target = this.obtainNode(edge[1], { from: 2 })
    }
  }

  // add a node.ins Map, key = SOURCE (not sourceid), value = edgePayload
  //
  // its source because it can be.  Node.outs has to use targetid
  // because the target object might not exist.
  setIns () {
    for (const node of this.loadedNodes.values()) {
      node.ins = new Map()
    }
    for (const source of this.loadedNodes.values()) {
      if (!source.outs) {
        console.error('no .outs map for node %O', source)
      }
      for (const [targetid, edgePayload] of source.outs) {
        const target = this.obtainNode(targetid)
        target.ins.set(source, edgePayload)
      }
    }
  }
  
  /**
     Return a list of setEdgePayload event calls which would be
     necessary to make this graph have the edges in "newer".

     DESTRUCTIVE to "newer" -- deletes edges found in both.

     Returns lists of [    [s, t, p], [s, t, p] ... ]
  */
  * computePatchTo (newer) {    // rename to computeEdgePatchTo ?
    // this.debug('computePatchTo (from %o edges to %o edges)',
    //    this.edgeCount(), newer.edgeCount())
    // this.debug('  from %O', [...this.edges()])
    // this.debug('  to   %O', [...newer.edges()])
    for (const [sourceid, targetid, payload] of this.edges()) {
      // this.debug('.. now: %o -> %o', sourceid, targetid)
      const newPayload = newer.getEdgePayload(sourceid, targetid)
      newer.setEdgePayload(sourceid, targetid, null)
      if (newPayload && equal(newPayload, payload)) {
        // this.debug('.. .. unchanged')
      } else {
        // this.debug('.. .. payload changed to %o', newPayload)
        yield ([sourceid, targetid, newPayload])
      }
    }
    for (const [sourceid, targetid, payload] of newer.edges()) {
      yield ([sourceid, targetid, payload])
    }
  }

  applyPatch (patch) {
    // this.debug('applying patch')
    for (const entry of patch) {
      // this.debug('.. patch step: %o', entry)
      this.setEdgePayload(...entry)
    }
  }

  * edges () {
    for (const source of this.loadedNodes.values()) {
      for (const [targetid, payload] of source.outs) {
        // check deleted target node?
        yield [source.id, targetid, payload]
      }
    }
  }

  outMap (node) {
    node = this.obtainNode(node)
    if (node) return node.outs
    return null
  }

  // we can be a BackingStore, too
  
  async forEachEdge (sourceid, options, callback) {
    // in case we're also a front
    if (this.loadOuts) {
      await this.loadOuts(sourceid, options)
    }
    
    const node = this.obtainNode(sourceid)
    if (!node) return null
    for (const [targetid, edgePayload] of node.outs) {
      let res = callback(sourceid, targetid, edgePayload)
      if (res.then) res = await res
      if (res === 'break') break
    }
    return true
  }

  async getNodePayload (nodeid, options) {
    let node = this.loadedNodes.get(nodeid)
    if (!node && this.loadNode) {
      // in case we're also a front
      node = await this.loadNode(nodeid, options)
    }
    return node && node.payload
  }

  silentClear () {
    this.loadedNodes.clear()
  }

  nodes () {
    return this.loadedNodes.values()
  }
  
  dump () {
    return {
      nodes: [...this.nodes()],
      edges: [...this.edges()]
    }
  }

  copyFrom (other) {
    for (const edge of other.edges()) this.setEdgePayload(...edge)
    for (const node of other.nodes()) this.setNodePayload(node.id, node.payload)
    this.roots.push(...other.roots)
  }

  /*
    Find and return a subgraph containing all the nodes/edges which
    can reach sink.
  */
  subgraphLeadingIn (sink) {
    sink = this.obtainNode(sink)
    this.setIns() // maybe set a flag that we've done this, unset with change?

    const result = new Graph()
    result.setNodePayload(sink.id, sink.payload)
    
    const toDo = [sink]

    while (toDo.length) {
      const cur = toDo.shift()

      // don't go upstream past the root, thanks
      // console.log('upstream? cur=%o roots=%o', cur, this.roots)
      if (this.roots.includes(cur.id)) {
        // console.log('upstream skip')
        continue
      }

      for (const [source, edgePayload] of cur.ins) {
        if (!result.hasNodeId(source.id)) {
          toDo.push(source)
          result.setNodePayload(source.id, source.payload)
        }
        if (source.id !== cur.id) {
          result.setEdgePayload(source.id, cur.id, edgePayload)
        }
      }
    }
    return result
  }

  // yields all the acyclic parths between start and end, not including them.
  //
  // either can be a node or id
  //
  // not very efficient.
  * allPathsInner (start, endid, seen, depth = 0) {
    start = this.obtainNode(start)
    endid = this.nodeId(endid)
    if (!seen) seen = new Set()

    if (start.id === endid) throw 'start and end the same in gg.allPathsInner'

    // console.log('%d start = %o', depth, start.id)
    for (const [targetid] of start.outs) {
      if (targetid === endid) {
        yield []
      } else {
        if (!seen.has(targetid)) {
          const target = this.obtainNode(targetid)
          if (target) {
            seen.add(targetid)
            for (const path of this.allPathsInner(target, endid, seen, depth + 1)) {
              yield [target, ...path]
            }
            seen.delete(targetid)
          }
        }
      }
    }
  }

  * allPaths (start, end) {
    start = this.obtainNode(start)
    end = this.obtainNode(end)
    if (start.id === end.id) {
      yield [start, end]
    } else {
      for (const p of this.allPathsInner(start, end.id)) {
        yield [start, ...p, end]
      }
    }
  }

  // just for testing
  addPath (ids, payload = {}) {
    if (typeof ids === 'string') ids = ids.split(/\s+/)
    let source = this.obtainNode(ids.shift(), payload)
    for (const targetid of ids) {
      const target = this.obtainNode(targetid, payload)
      this.setEdgePayload(source, target, {})
      source = target
    }
  }

  asciiDraw (delim = '\n') {
    const out = []
    for (const [sourceid, targetid, payload] of this.edges()) {
      const parts = [sourceid, targetid]
      let str = JSON.stringify(payload)
      if (str !== '{}') parts.push(str)
      out.push(parts.join(' '))
    }
    out.sort()
    return out.join(delim)
  }

  // given a path (an iterable of nodes), copy the nodes+edges on the
  // path into another gg called dest.
  copyPath (path, dest) {
    let source = path[0]
    for (const target of path.slice(1)) {
      // shouldn't copy if it already exists, eh?  maybe the deepcopy
      // should be in obtainNode and setEdgePayload?
      const ds = dest.obtainNode(source.id, deepcopy(source.payload))
      const dt = dest.obtainNode(target.id, deepcopy(target.payload))
      dest.setEdgePayload(source.id, target.id, deepcopy(this.getEdgePayload(source, target.id)))
      source = target
    }
  }

  logParamChange (msg) {
    if (!this.paramChangeLog) this.paramChangeLog = []
    this.debug('param change: %o', msg)
    // console.trace() -- not working?!
    // but this does:   (comment out when not using -- slow!)
    // try { throw Error('trace') } catch(e) {this.debug(e.stack)}
    this.paramChangeLog.push(msg)
  }
  clearParamChange () {
    this.paramChangeLog = null
  }
  checkParamChange () {
    if (this.paramChangeLog) {
      this.debug('checkParamChange reporting: %O', this.paramChangeLog)
    }
    return !!this.paramChangeLog
  }
}

module.exports = { Graph }
