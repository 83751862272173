import React from 'react'
import { useUser } from './User'
// import { Container, Heading, Section, Hero } from 'react-bulma-components';
// this doesn't seem to show up any different.  no sass?
import Container from 'react-bulma-components/lib/components/container'
import Heading from 'react-bulma-components/lib/components/heading'
import Section from 'react-bulma-components/lib/components/section'
import Hero from 'react-bulma-components/lib/components/hero'
import Columns from 'react-bulma-components/lib/components/columns'
import { brand } from './branding'

export default function Splash ({ client, params, nav }) {
  const user = useUser(client)
  return (
    <>
      <Hero color='primary' gradient>
        <Hero.Body>
          <Columns>
            <Columns.Column size={1}>
              <img src={brand.logo} alt='Brand Logo' />
            </Columns.Column>
            <Columns.Column>
              <Container>
                <Heading size={2}>
                  {brand.name}
                </Heading>
                <Heading subtitle size={3}>
                  {brand.tagline}
                </Heading>
              </Container>
            </Columns.Column>
          </Columns>
        </Hero.Body>
      </Hero>
      {user
        ? <Section>
          {/*
        <article className="message">
          <div className="message-header">
            <p>Logged in as {user.screenName}</p>
          </div>
          <div className="message-body">
            <p>
              To continue, select item from menu above
            </p>
          </div>
        </article>
        */}

          <Columns>
            <Columns.Column>
              <a href={nav.linkForPage('Reports')} className='mb-3 button xis-small is-primary xis-rounded'>Reports</a>
              <br />
              When you're not sure how much to believe something online, get a <b>Credibility Report</b>, built from sources you've indicated you currently trust.
            </Columns.Column>
            <Columns.Column>
              <p className='mb-6'><a href={nav.linkForPage('Notes')} className='mb-3 button xis-small is-primary xis-rounded'>Notes</a> <br />An easy way to keep track of the <b>reasons you trust or
                distrust
                                                                                                                                                                               </b> an online source, while helping guide the people who
                trust you.
              </p>
            </Columns.Column>
            <Columns.Column>
              <a href={nav.linkForPage('Explore')} className='mb-3 button xis-small is-primary xis-rounded'>Explore</a>
              <br />See who you're connected to <b>directly and indirectly,</b> and improve community collaboration.
            </Columns.Column>
            <Columns.Column>
              <p className='mb-6'><a href={nav.linkForPage('Activity')} className='mb-3 button xis-small is-primary xis-rounded'>Activity</a> <br />See recent notes from others in your network, so you
                can <b>help everyone improve</b> the quality of their trust
                decisions.
              </p>
            </Columns.Column>
          </Columns>
        </Section>
        : <Section>
          <article className='message is-dark'>
            <div className='message-header'>
              <p>Invited users only (for now)</p>
            </div>
            <div className='message-body'>
              <ul>
                <li className='mb-4'>
                  If you have an account, please log in.
                </li>
                <li className='mb-4'>
                  If you have an invitation link, visit that link.
                </li>
                <li className='mb-4'>
                  If you have an invitation code, use the Login button, then enter the code.
                </li>
                <li className='mb-4'>
                  Otherwise, please check back soon!
                </li>
              </ul>
            </div>
          </article>
        </Section>}
    </>
  )
}
