import React from 'react'

// I like how modal-card-head looks

export function Title ({ children }) {
  return (
    <header class='modal-card-head'>
      <p className='modal-card-title'>{children}</p>
    </header>
  )
}

export default function Card ({ title, buttons, children }) {
  // we don't actually wrap it in modal-card style
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title || 'Details'}</p>
      </header>
      <section className='modal-card-body'>
        {children}
      </section>
      {buttons &&
        <footer className='modal-card-foot'>
          <div className='buttons'>
            {buttons}
          </div>
        </footer>}
    </>
  )
}
