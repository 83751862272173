import React from 'react'
import { params, useParam } from './params'
// import { useUser } from './User'

params.string('Invitation Code', { history: false })

export default function UseInvitation ({ client, params, nav }) {
  const [invitationCode, setIC] = useParam('invitationCode')
  // const user = useUser(client)
  const [parsed, setParsed] = React.useState()

  React.useEffect(() => {
    (async () => {
      if (invitationCode) {
        const r = await client.findInvitation(invitationCode)
        setParsed(r)
      }
    })()
  }, [client, invitationCode, setParsed])

  // if (!invitationCode) closeModal()

  if (parsed) {
    const { by, to, inv } = parsed
    return (
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Login using invitation code</p>
          <button type='button' className='delete' aria-label='close' onClick={nav.closeModal} />
        </header>

        <section className='modal-card-body'>

          <p className='pb-6'>We recommend you set a <u>password</u> and <u>account recovery method</u> soon.  Until you do, anyone with the invitation code can use this account.</p>

          <p className='pb-2'>Invitation details:</p>

          <p><b>From:</b> {by.screenName} (account #{by.id})</p>
          <p><b>To:</b> {to.screenName} (account #{to.id})</p>
          <p><b>Date:</b> {inv.created}</p>

          {/* <pre>{JSON.stringify(parsed, null, 2)}</pre> */}
        </section>
        <footer className='modal-card-foot'>
          <div className='buttons'>
            <button
              type='button' className='button' onClick={() => {
                console.log('cancel invitation login')
                // for some reason this works on the dev server but
                // not the production one.  wtf.  BUG.
                nav.closeModal({all: true})
                params.data('invitationCode', '')
                setIC('')
              }}
            >Cancel
            </button>
            <button
              type='submit' className='button is-primary'onClick={async () => {
                console.log('UseInvitation accepting')
                await client.login({ invitationCode })
                console.log('UseInvitation accepted', client.user)
                nav.closeModal({all: true})
                params.data('invitationCode', '')
                setIC('')
              }}
            >Login as {to.screenName}
            </button>
          </div>
        </footer>
      </div>
    )
  } else {
    return (
      <div className='box'>
        Checking invitation...
      </div>
    )
  }
}

/*

  It's hard to clear the invitation code, for some reason I don't understand

  https://github.com/facebook/react/issues/18178#issuecomment-602323184

*/
