import { useParam } from './params'
import { useFocus } from './useFocus'

export function useNav ({ params, components }) {
  const [modals, setModals, linkForModals] = useParam('modals', params)
  const [pages, setPages, linkForPages] = useParam('page', params)
  const [focusId, setFocusId, focusLink] = useFocus(params)

  const componentByName = name => {
    const c = components[name]
    if (!c) throw new Error('Missing globalComponent ' + JSON.stringify(name))
    return c
  }

  const modalNames = modals.split('-')
  const modalName = modalNames[modalNames.length - 1]

  const closeModal = (options) => {
    if (options && options.all) {
      setModals('')
      return
    }
    const modals = params.data('modals') // get it right now, not via react
    if (modals) {
      const modalNames = modals.split('-')
      setModals(modalNames.slice(0, -1).join('-'))
    } else {
      console.error('closeModal() called without open modal')
    }
  }

  const openModal = (name, options) => {
    const modals = params.data('modals') // get it right now, not via react
    let modalNames = []
    if (modals) modalNames = modals.split('-')
    setModals(modalNames.concat([name]).join('-'))
  }

  const linkForModal = (name) => {
    const modals = params.data('modals') // get it right now, not via react
    let modalNames = []
    if (modals) modalNames = modals.split('-')
    return linkForModals(modalNames.concat([name]).join('-'))
  }

  const pageNames = pages.split('/')
  const pageName = pageNames[pageNames.length - 1]
  const parentPage = pageNames.slice(0, -1).join('/')

  const openPage = (name, options) => {
    if (options && options.fresh) {
      params.reset()
      return
    }
    if (options && options.sub) {
      const pageNames = pages.split('/')
      setPages(pageNames.concat([name]).join('/'))
    }
    setPages(name)
  }

  const linkForPage = (name, options) => {
    // fresh?
    // too hard
    //
    if (options && options.sub) {
      const pageNames = pages.split('/')
      return linkForPages(pageNames.concat([name]).join('/'))
    }
    return linkForPages(name)
  }

  const close = () => {
    const modals = params.data('modals') // get it right now, not via react
    if (modals) {
      const modalNames = modals.split('-')
      setModals(modalNames.slice(0, -1).join('-'))
    } else {
      const pages = params.data('page')
      const pageNames = pages.split('/')
      const parentPage = pageNames.slice(0, -1).join('/')
      if (parentPage) {
        setPages(parentPage)
      } else if (focusId) {
        setFocusId('')
      } else {
        setPages('')
      }
    }
  }

  const switchTab = (tabs, n) => {
    const i = tabs.indexOf(pageName)
    let i2 = i + n
    if (i2 >= tabs.length) i2 = -1
    if (i2 < -1) i2 = tabs.length - 1
    openPage(tabs[i2] || '')
  }

  return {
    modalName,
    openModal,
    closeModal,
    linkForModal,
    switchTab,
    parentPage,
    pageName,
    openPage,
    linkForPage,
    componentByName,
    close,
    focusId,
    setFocusId,
    focusLink
  }
}
