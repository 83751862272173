// one default for now
import logo from './icons8-trust-100.png'

// colors?

const logoIncludesName = false

const defaults = { logo, logoIncludesName }

const dev = {
  isDev: true,
  name: 'Cred-Dev',
  site: 'http://localhost:3000/',
  tagline: 'Developing Credibility Software FTW ASAP',
  ...defaults
}

const trustlamp = {
  name: 'TrustLamp',
  site: 'https://trustlamp.com',
  // tagline: 'Shine a light on what is trustworthy',
  // tagline: 'Where people work together in deciding which sources to trust',
  // tagline: 'Help your community decide who to trust',
  // tagline: 'Build trust online',
  // tagline: 'Smart online trust',
  // tagline: 'Enlightening credibility',
  // tagline: 'Who should you believe?',
  // tagline: 'Rebuilding trust online',
  tagline: 'Helping people trust wisely',
  ...defaults
}

const credbug = {
  name: 'CredBug',
  site: 'https://credbug.com',
  tagline: 'Fixing credibility',
  ...defaults
}

const credmutt = {
  name: 'CredMutt',
  site: 'https://credmutt.com',
  tagline: 'Helping you find what\'s true',
  ...defaults
}

const credroot = {
  name: 'CredRoot',
  site: 'https://credroot.com',
  tagline: 'Growing trustworthy connections',
  ...defaults
}

const credshift = {
  name: 'CredShift',
  site: 'https://credshift.com',
  tagline: 'A better landscape for trust',
  ...defaults
}

const peepcred = {
  name: 'PeepCred',
  site: 'https://peepcred.com',
  tagline: 'See who your "peeps" find credible',
  ...defaults
}

const brands = {
  dev, trustlamp, credbug, credmutt, credroot, credshift, peepcred
}

const brand = brands[process.env.REACT_APP_BRAND || 'trustlamp']

document.title = brand.name

export default brand
export { brand, brands }
