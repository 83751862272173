import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { useClient } from './useClient'
import { useQuery } from './useQuery'
import { useParam } from './params'
import { useFocus } from './useFocus'
import Card from './Card'
import { U, Face, Account, accountSorter } from './formatting'

export { U, Face, Account, Card, accountSorter }
export { useClient, useQuery, useParam, useFocus }

TimeAgo.addLocale(en)

const rawTimeAgo = new TimeAgo('en-US')

export const ago = t => {
  if (t) return rawTimeAgo.format(t)
  return 'at unknown time'
}

export function pct (n) {
  if (typeof n === 'number' && !isNaN(n)) return Math.round(n * 100)
  return '—' // mdash
}
