/* eslint-env browser */
import React from 'react'
import { U, Card } from './common'
import classnames from 'classnames'
import visiparam from 'visiparam'
import equal from 'fast-deep-equal'
// import { SelectNetworks } from './Networks'

export default class Vote extends React.Component {
  constructor (props) {
    super(props)
    this.state = { }

    this.handleSave = this.handleSave.bind(this)
    this.onClientChange = this.onClientChange.bind(this)

    this.debug = (...args) => console.log(...args, 'Vote')
  }

  getRemoteState () {
    if (!this.node) return {}
    const userEdge = this.props.client.getUserEdge(this.node.id) || { score: 0, reason: '' }

    const state = {
      score: '' + (userEdge.score * 100 || 50),
      text: userEdge.reason || '',
      knows: userEdge.knows || 0,
      networks: userEdge.networks || userEdge.worlds /* legacy */ || {}
    }

    return state
  }

  onClientChange () {
    this.debug('onClientChange')
    const id = visiparam.data('focusNode')
    // console.warn('Vote onClientChange, id=%o', id)
    if (!id) return // no focus

    this.node = this.props.client.gg2.obtainNode(id)
    if (!this.node) return // not loaded yet

    const newRemoteState = this.getRemoteState()
    this.debug('newRemoteState is', newRemoteState)
    
    if (equal(newRemoteState, this.remoteState)) {
      this.debug('.. which is no change')
      return
    }
    if (this.remoteState) {
      /*
        Alternative would be to have the server share the UI state between sessions, before saving.  That's probably not worth it.
      */
      alert('It looks like you just changed your answers in another session. You can "Cancel" then "Edit" again to get updated information here. If you "Share" here, the answers from the other session will be over-written.')

      this.remoteState = newRemoteState
      this.debug('.. which is a breaking change')
    } else {
      this.remoteState = newRemoteState
      this.debug('.. which means setState')
      this.setState(newRemoteState)
    }
  }

  userHasChangedState () {
    const changed = !equal(this.state, this.remoteState)
    this.debug('vote userHasChangedState from %o to %o yes? %o', this.remoteState, this.state, changed)
    this.debug('vote backup is %o', this.backupRS)
    this.debug('vote this.backupRS to', JSON.stringify(this.backupRS))
    return changed
  }

  componentDidMount () {
    // console.warn('Vote did mount')

    this.onClientChange()
    this.props.client.on('change', this.onClientChange)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.client !== this.props.client) {
      console.warn('<Vote> client changed??? was=%o now=%o', prevProps.client, this.props.client)
      prevProps.client.off('change', this.onClientChange)
      this.componentDidMount()
    }
  }

  componentWillUnmount () {
    this.props.client.off('change', this.onClientChange)
  }

  handleSave () {
    const node = this.node
    const score = this.state.score

    const overlay = { target: node.id, prov: 'manually set' }
    if (score === 50) {
      overlay.score = null
    } else {
      let s = parseFloat(score) / 100
      if (s < 0) s = 0
      if (s > 0.99) s = 0.99
      overlay.score = s
    }
    if (this.state.text) overlay.reason = this.state.text
    overlay.knows = this.state.knows
    overlay.networks = this.state.networks
    this.props.client.setUserEdge(overlay)
    // give some kind of confirmation?
    delete this.remoteState
  }

  render () {
    this.debug('render')
    const setScore = score => this.setState({ score })
    let n = 1
    const node = this.node
    if (!node) return '' // no focus or not loaded

    const name = node.payload.screenName

    const rangeStyle = {
      width: '100%',
      height: '15px',
      borderRadius: '5px',
      outline: 'none',
      background: '#d3d3d3',
      opacity: 0.7
    }

    const score = this.state.score || 50

    let qual = 'dangerously misleading'
    if (score >= 15) qual = 'not credible'
    if (score >= 45) qual = 'not quite credible'
    if (score >= 49) qual = 'of unknown quality'
    if (score >= 51) qual = 'barely credible'
    if (score >= 55) qual = 'somewhat credible'
    if (score >= 65) qual = 'fairly credible'
    if (score >= 75) qual = 'credible'
    if (score >= 85) qual = 'highly credible'
    if (score >= 92) qual = 'extremely credible'
    let msg = (<span>"As an information source, I generally find <U node={node} /> to be <b>{qual}</b>."</span>)
    if (score === 50) msg = <span>"I am not stating any opinion on the credibility of <U node={node} /> as an information source."</span>

    return (
      <div style={this.props.style || { height: '100%' }} className='container is-wide'>
        <Card title={<span>Your notes on <U node={node} /></span>}>
          <p className='mt-0'>Your answers to these questions are a contribution to anyone who trusts you, today or in the future. <b>Your answers are public.</b> We encourage you to be thoughtful and share whatever might be helpful to your community. You can change your answers later, but previous answers may still be available in the edit history.</p>

          <p className='mt-5'><b>{n++}. Do you know {name}?</b> Your answer will help people who trust you understand your other answers. Knowing someone does not imply that you trust or endorse them.</p>

          <HowWell
            name={<u><U node={node} nav={this.props.nav} /></u>}
            selected={this.state.knows}
            setSelected={n => this.setState({ knows: n })}
          />

          {/*
        <p className='mt-5'><b>{n++}. In which parts of your life would you be likely to talk about {name}?</b> Your answer will help the people who trust you find the information that matters to them. Select any/all that apply.</p>

        <SelectNetworks client={this.props.client}
          selected={this.state.networks}
          setSelected={v => this.setState({ networks: v })}
          />  */}

          <p className='mt-4'><label><b>{n++}. Briefly, what experiences most influence how much <em>you</em> trust {name}?</b> (optional) Your answer will help people who trust you decide how much to trust this source. It may also help people understand why their views are different from yours. Also, you should disclose any reason you can't be fully transparent. </label>
            <textarea
              rows={2} style={{ width: '100%' }} onChange={
            event => this.setState({ text: event.target.value })
}
              value={this.state.text}
            />
            {/* <input id="reason" type="text" value={text} size="60" onChange={event => setText(event.target.value)} />  */}
          </p>

          <div className='mt-5'><b>{n++}. How much should people rely on what {name} says?</b>

            <input className='ml-4 mr-4' type='text' value={score} size='2' onChange={event => setScore(event.target.value)} /> Scale 1-99
            <input style={rangeStyle} type='range' min='1' max='99' value={score} onChange={event => setScore(event.target.value)} />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <span style={{ flex: '0 1' }}>Not&nbsp;at&nbsp;all</span>
              <span style={{ flex: '0 1' }}>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;opinion</span>
              <span style={{ flex: '0 1' }}>Completely</span>

            </div>

            <ButtonRow setScore={setScore} />
          </div>

          <p className='mt-3'>With this rating, you are saying: {msg}</p>

          <div className='buttons mt-5' style={{ visibility: this.userHasChangedState() ? 'visible' : 'hidden' }}>
            <button onClick={() => this.setState(this.remoteState)} disabled={!this.userHasChangedState()} className='button is-danger'>Undo changes</button>
            <button onClick={this.handleSave} disabled={!this.userHasChangedState()} className='button is-primary'>Save and Share changes</button>
          </div>
        </Card>
      </div>
    )
  }
}

function ButtonRow ({ setScore }) {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <button style={{ width: '12%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(1)}>Dangerous</button>
      <button style={{ width: '27%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(20)}>Not Credible</button>
      <button style={{ width: '16%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(50)}>No Opinion</button>
      <button style={{ width: '8%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(60)}>Some</button>
      <button style={{ width: '8%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(70)}>Fair</button>
      <button style={{ width: '14%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(80)}>Credible</button>
      <button style={{ width: '10%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(90)}>High Cred</button>
      <button style={{ width: '4%', marginRight: 1 }} className='button is-small is-rounded is-primary' onClick={() => setScore(95)}>!</button>
    </div>
  )
}

function HowWell ({ name, selected, setSelected }) {
  // const [selected, setSelected] = React.useState(2)
  selected = 2 - selected // outside thinks they're numbered 2, 1, 0
  const mySetSelected = n => setSelected(2 - n)

  const options1 = ['We know each other',
    'I know who that is',
    'Not sure / skip'
  ]

  const options2 = [
    <span key={1}>With this answer, you are saying "If {name} and I saw each other walking down the street, we would recognize each other and be able to talk about our previous conversations and activities</span>,
    <span key={2}>With this answer, you are saying "If someone mentioned {name}, I would be confident I knew who they were talking about, and I could add my own opinions or stories to the conversation</span>,
    <span key={3}>With this answer, no information will be provided to others about whether you know {name}</span>
  ]

  return (
    <div>
      <Radio options={options1} selected={selected} setSelected={mySetSelected} />
      <div style={{ marginTop: '1rem', marginLeft: '2rem', height: '4rem', overflowY: 'scroll' }}>
        {options2[selected] || <span><em>Select an option for more details.</em></span>}
      </div>
    </div>
  )
}

function Radio ({ options, selected, setSelected }) {
  const optionElements = []
  let i = 0
  for (const option of options) {
    const ii = i
    optionElements.push(
      <div
        key={option} style={{ flex: '1 1 50px', margin: '2px' }}
        className={classnames('button is-rounded', { 'is-active is-primary': selected === i })}
        onClick={() => { setSelected(ii) }}
      >
        {option}
      </div>
    )
    i++
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      {optionElements}
    </div>
  )
}
