import React from 'react'
import { Content } from 'react-bulma-components'
import { brand } from './branding'
import Card from './Card'

export default function Feedback () {
  const mailto = `mailto:sandro@hawke.org?subject=feedback on ${brand.site}`

  return (
    <Card title='Giving Feedback'>
      <Content>
        <p>There are three good options:</p>
        <ul>
          <li>In general, we recommend the <a href='https://trustlamp.canny.io/'>bug/feature voting system</a></li>
          <li>If you're comfortable with GitHub, see our <a href='https://github.com/sandhawke/trustlamp/issues'>github issues</a></li>
          <li>Or just contact the project lead: <a href={mailto}>sandro@hawke.org</a></li>
        </ul>
      </Content>
    </Card>
  )
}
