import React from 'react'
// import { Heading, Section } from 'react-bulma-components'
import { ModalCard } from './modals'
// import { Formik, Field, Form } from 'formik'
// import equal from 'fast-deep-equal'

// make this one of those expanding search fields?

// right now, we have syncSearch and asyncSearch both running when
// you type.  Maybe asyncSearch should only run when you submit the
// field?   I think that's closer to what people expect.

export default function Search ({ client, params, closeModal, nav }) {
  const [text, setText] = React.useState('')
  const [results, setResults] = React.useState(false)
  const [results2, setResults2] = React.useState(false)
  const [done, setDone] = React.useState(false)

  const onChange = async event => {
    const target = event.target
    const n = target.value
    setText(n)
    const r1 = client.syncSearch(n)
    setDone(false)
    setResults(r1)

    const r2 = (await client.asyncSearch([], n))
    setDone(true)
    if (n !== target.value) return
    if (!r2) return
    setResults2(r2)
  }

  return (
    <ModalCard title='Search' closeModal={closeModal}>
      <label htmlFor='nodesearch'>Search for source (name or URL)</label>
      <input
        type='text'
        size='60'
        id='nodesearch'
        value={text}
        onChange={onChange}
        placeholder='Annabeth -or- https://media.example/annabeth'
        autoFocus
      />
      <div className='mt-4'>
        Local results:
        {results
          ? <Results results={results} text={text} params={params} done={done} nav={nav} />
          : <p>Enter search string above</p>}
      </div>
      <hr />
      <div className='mt-4'>
        From server:
        {results2
          ? <Results results={results2} text={text} params={params} done={done} nav={nav} />
          : <p />}
      </div>
    </ModalCard>
  )
}

// list of nodes, where you can click on one to close modal and set focus
function Results ({ results, text, params, done, nav }) {
  const f = node => {
    const onClick = () => {
      // console.log('setFocus and close modal, to %o', node.id())
      params.data('focusNode', node.id)
      nav.closeModal({ all: true })
      if (!nav.pageName) nav.openPage('Reports')
    }
    return (
      <div key={node.id} className='button is-small' onClick={onClick}>
        <div>
          {node.data('screenName')}
        </div>
      </div>
    )
  }
  if (results.length) {
    return results.map(f)
  } else {
    if (done) {
      return 'no results found'
    } else {
      return 'searching...'
    }
  }
}

/*
  return (
    <Formik
      initialValues={{
        text: ''
      }}
      onSubmit={async values => {
        console.log('submitting', values)

        try {
          if (values.ic) {
            await client.acceptInvitation(values.ic)
          } else {
            await client.login(values)
          }
          console.log('logged in %O', client.user)
          closeModal()
        } catch (e) {
          if (e.code === 'BAD_AUTH') {
            setMessage('unknown user or incorrect password')
          } else {
            setMessage('Unknown error: ' + JSON.stringify(e))
          }
        }
      }}
    >
      {({ isSubmitting, handleReset }) => (
        <Form id="login-form">

          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Login</p>
             <button type="button" className="delete" aria-label="close" onClick={closeModal}></button>
            </header>

            <section className="modal-card-body" onClick={bodyClick}>

              <div className="columns">
                <div className="column" style={{borderRight: '1px solid gray'}}>

                  <p>Use email/password:</p>

                  <div className="field">
                    <label className="label"htmlFor="email">Email</label>
                    <Field
                      tabIndex="0"
                      id="email"
                      name="email"
                      placeholder="jane@acme.com"
                      type="email"
                    />
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="cw_pw">Password</label>
                    <div className="control">
                      <Field tabIndex="0" id="cw_pw" name="cw_pw" placeholder="" type="password" />
                    </div>
                  </div>
                </div>
                <div className="column">
                  Or use:

                  <div className="field">
                    <label className="label" htmlFor="ic">Invitation Code</label>
                    <div className="control">
                      <Field tabIndex="0" id="ic" name="ic" placeholder="" />
                    </div>
                  </div>
                 </div>
              </div>

              <p>
                <b>
                  {message}
                </b>
              </p>

            </section>
            <footer className="modal-card-foot">
              <div className="buttons">
                <button type="button" className="button" onClick={closeModal}>Cancel</button>
                <button type="button" className="button" onClick={handleReset}>Reset</button>
                <button disabled={isSubmitting} type="submit" tabIndex="0" form="login-form" className="button is-primary">Login</button>
              </div>
            </footer>
          </div>
        </Form>
      )}
    </Formik>
  )
*/
