const style = [
  {
    selector: 'node[msg]',
    style: {
      content: 'data(msg)'
    }
  },
  {
    selector: 'node',
    style: {
      // 'background-color': 'green',
      shape: 'rectangle',
      padding: 5,
      'text-valign': 'center',
      width: 'label'

    }
  },
  {
    selector: 'node[score=1]',
    style: {
      // 'background-color': 'green',
      shape: 'ellipse',
      padding: 10
    }
  },
  {
    selector: 'node[score<0.895]',
    style: {
      // 'background-color': '#8f8'
    }
  },
  {
    selector: 'node[score<0.745]',
    style: {
      // 'background-color': 'yellow'
    }
  },
  {
    selector: 'node[score<0.495]',
    style: {
      // 'background-color': 'red'
    }
  },
  {
    selector: 'edge[msg]',
    style: {
      label: 'data(msg)'
    }
  },
  {
    selector: 'edge',
    style: {
      'line-color': '#ccc',
      'target-arrow-color': '#ccc',
      width: 5,
      // 'target-label': 'data(targetScore)',
      // 'target-text-offset': 15,
      'curve-style': 'bezier',
      'target-arrow-shape': 'triangle',
      'text-rotation': 'autorotate' // align to edge
    }
  },
  {
    selector: 'edge.outlier',
    style: {
      'line-color': '#fbb',
      'target-arrow-color': '#fbb'
    }
  },

  {
    selector: 'edge:selected',
    style: {
      color: '#00F',
      'line-color': '#CCF',
      width: 15,
      'target-arrow-color': '#CCF'
    }
  },
  // make selected have a blue border without affecting spacing
  {
    selector: 'node',
    style: {
      'border-color': '#FFF', // or the score color??
      'border-width': 3
    }
  },
  {
    selector: 'node:selected',
    style: {
      'border-color': '#00F',
      'border-width': 3
    }
  },
  {
    selector: 'node.statement',
    style: {
      'border-color': 'black',
      'border-width': 5
    }
  }
]

export default style
// module.exports = style
