import React from 'react'

import spinningImage from './Lava Lamp-2s-164px.gif'
// import spinningImage from './Lava Lamp-2s-317px.gif'
// import spinningImage from './Gear-0.5s-61px.gif'

const activities = new Set()
window.busy = activities

let elem
let msg

const updateDisplay = () => {
  if (!elem) return
  let display = 'block'
  if (activities.size === 0) {
    display = 'none'
  }
  elem.style.display = display
}

// total hack, because I want this to be basically independent of
// react.  We only use react for the JSX, really.
const waitForDiv = () => {
  elem = document.getElementById('mainSpinner')
  msg = document.getElementById('busyMessage')
  window.busyDiv = elem

  if (elem) return
  setTimeout(waitForDiv, 1)
}
setTimeout(waitForDiv, 10)

export default function busy (name = 'anon') {
  const act = { name, start: Date.now() }
  activities.add(act)
  if (msg) msg.innerText = name
  const done = () => {
    console.log('was busy for ', Date.now() - act.start, 'ms, doing', name)
    activities.delete(act)
    setTimeout(updateDisplay, 500)
  }
  setTimeout(updateDisplay, 500)
  // console.log('busy doing %o', [...activities].map(x => x.name).join(','))

  return done
}

export function Busy () {
  return (
    <div id='mainSpinner' className='mainSpinner' style={{ display: 'block', position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 11, background: 'rgba(0,0,0,0.4)', pointerEvents: 'none' }}>
      <img style={{ position: 'fixed', left: 'calc(50vw - 82px)', top: 'calc(50vh - 82px)', zIndex: 11, borderRadius: '10%' }} src={spinningImage} alt='spinner' />
      <span style={{ position: 'fixed', left: 1, bottom: 1, background: 'white', fontFamily: 'arial', fontSize: 12, paddingRight: 5 }} id='busyMessage'>Connecting to server...</span>
      {// <progress className="progress is-small is-primary" max="100">running...</progress>
      }
    </div>
  )
}
