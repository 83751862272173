import { useParam } from './params'

export function useFocus (params, gg) {
  if (!params) console.warn('best call useFocus with params')
  if (params.params) params = params.params // let it be passed by name
  if (params.gg) gg = params.gg

  const [focusId, setFocusId, focusLink] = useParam('focusNode', params)
  const [focusLId, setFocusLId, focusLLink] = useParam('focusLink', params)
  const r = [focusId, setFocusId, focusLink] // backward compat
  r.id = focusId
  if (gg) r.node = gg.obtainNode(r.id, null)
  r.setId = setFocusId
  r.linkToSetId = focusLink

  if (focusLId) {
    const [sourceid, targetid] = focusLId.split(' ')
    r.sourceid = sourceid
    r.targetid = targetid
  }
  r.setEdge = (s, t) => {
    if (s && t) {
      setFocusLId(s + ' ' + t)
    } else if (!s && !t) {
      setFocusLId()
    } else {
      throw RangeError('set both source and target id, or neither')
    }
  }
  r.setEdgeLink = (s, t) => {
    if (s && t) {
      return focusLLink(s + ' ' + t)
    } else if (!s && !t) {
      return focusLLink()
    } else {
      throw RangeError('set both source and target id, or neither')
    }
  }
  return r
}
