/*
  shortcuts[27] = runThisOnEscape()
or
  shortcuts.Escape = runThisOnEscape()

  Such as maybe:
  - Escape nav.close()
  - LeftArrow nav.prevTab()
  - RightArrow nav.nextTab()
  - Enter focus on current row
  - Up/Down arrow next/prev focus or selected row
     or maybe raise / lower values?

*/

const shortcuts = {}

const fromEdge = {
  Down: 'ArrowDown',
  Up: 'ArrowUp',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Esc: 'Escape'
}

document.addEventListener('keydown', event => {
  console.log('shortcut keydown', event)
  const e = event || window.event
  let f = shortcuts[e.keyCode]
  if (!f) {
    const key = fromEdge[e.key] || e.key
    f = shortcuts[key]
  }
  if (f) {
    if (f()) event.preventDefault()
  } else {
    console.log('untrapped keydown', e.keyCode, e.key)
  }
})

export default shortcuts
