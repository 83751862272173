import React from 'react'
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import { pct, useQuery, useFocus } from './common'
import Diagram from './Diagram'

import { accountSorter, Account, U } from './formatting'
// import { Dropdown } from 'react-bulma-components/full';
import Dropdown from 'react-bulma-components/lib/components/dropdown'
// import FocusNode from './FocusNode'

export default function Explore (props) {
  const [focusId, setFocusId] = useFocus(props.params)
  const pp = Object.assign({ focusId, setFocusId }, props)

  if (focusId) return <Focused {...pp} />
  return <Unfocused {...pp} />
}

const cmp = (a, b) => {
  return b.payload.score - a.payload.score
}

const query = client => {
  const nodes = [...client.gg2.nodes()].filter(n => n.payload.score !== undefined)
  nodes.sort(cmp)

  const data = []

  let rank = 1
  for (const n of nodes) {
    data.push({ rank, score: pct(n.payload.score), node: n, client })
    rank++
  }
  return data
}

function Unfocused (props) {
  const { client, setFocusId, nav } = props
  const data = useQuery(client, query)
  // const [ diagram, setDiagram ] = React.useState(false)

  const columns = [
    // { title: 'Rank', field: 'rank', width: 75 },
    { title: 'Should people trust...', field: 'node', formatter: reactFormatter(<Account nav={props.nav} />), sorter: accountSorter },
    { title: 'Score', field: 'score', width: 75 },
    { title: 'Why that score?', formatter: reactFormatter(<Reason />), headerSort: false }
    // { title: 'URL', field: 'actions', hozAlign: 'center', width: 100, formatter: reactFormatter(<Show/>)},
  ]

  // const rowClick = React.useMemoize(() => {
  const rowClick = (event, row) => {
    const id = row.getData().node.id
    console.log('rowclick %o', id)
    setFocusId(id)
  }

  const options = { height: 'calc(100vh - 10rem)', rowClick }
  const tprops = { columns, data, options }

  return (
    <div style={{ height: '100%' }} className='container is-fullhd'>
      <div>
        <LimitDropdown client={client} />
        {client.user && /* client.user.id === 53 &&  GO WILD */
          <a
            className='ml-5 button is-rounded'
            href={nav.linkForPage('Explore/Guests')}
          >My Guests
          </a>}
        {/*
            --- Diagram all=true isn't working right at the moment ---

        <button className={'ml-5 button is-rounded' + (diagram ? ' is-primary' : ' ')}
                onClick={() => setDiagram(prev => !prev)}>Diagram</button>
                { diagram && <Diagram slot='cy1' all={true} {...props} /> */}
      </div>
      <ReactTabulator {...tprops} />
    </div>
  )
}

function LimitDropdown ({ client }) {
  const limit = n => {
    console.log('limit change to ', n, typeof n)
    // const was = client.limit || 5
    const l = Math.min(Math.max(Math.round(n), 1), 10000)
    client.limit = l
  }

  const sizes = [3, 10, 30, 100, 300, 1000, 3000, 10000]
  // const sizes = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000]

  const items = []
  for (const n of sizes) {
    const comment = n > 1000 ? ' (slow)' : ''
    items.push(<Dropdown.Item key={n} value={n}>Use {n} links{comment}</Dropdown.Item>)
  }

  return (
    <Dropdown onChange={n => limit(n)} label={`Using ${client.limit} links`}>
      {items}
    </Dropdown>
  )
}
/*
        <Tippy content="Use 90% fewer links"><button className={sizeButton} onClick={() => { limit(1 / 10)}}>- -</button></Tippy>
        <Tippy content="Use ~50% fewer links"><button className={sizeButton} onClick={() => { limit(1 / 1.58489319246)}}>-</button></Tippy>
        <span>Scope: using <span id="edgeCount">0</span> links</span>
        <Tippy content="Use ~50% more links"><button className={sizeButton} onClick={() => { limit(1.58489319246)}}>+</button></Tippy>
        <Tippy content="Use 10x more links(max 10k)"><button className={sizeButton} onClick={() => { limit(10)}}>+ +</button></Tippy>
    (reload to reset if too slow)
    </>
  )
}
*/

function Reason ({ cell }) {
  const props = cell.getRow().getData()
  return <Prov {...props} />
}

function Prov ({ node, client }) {
  const out = []
  for (const [source, edgePayload] of node.ins) {
    if (out.length > 0) {
      out.push(' [more...]') // link to prov details view
      break
    }

    const { prov, reason } = edgePayload // not using .score at the moment

    if (prov === 'manually set') {
      let said = 'gave score without a reason'
      if (reason) said = `said ${JSON.stringify(reason)}`
      out.push(<span key={source.id}><U node={source} link={false} /> {said}</span>)
    } else {
      out.push(<span key={source.id}>{prov} by <U node={source} link={false} /></span>)
    }
    /*
    const edge = client.graph.getEdge(p.edge.source, p.edge.target)
    out.push(<span key={edge.source().id}><U node={edge.source()} /> {edge.data('prov')}</span>) // JSON.stringify(edge.json()))
    */
  }
  return out
}

// eslint-disable-next-line no-unused-vars
function Cursors ({ node }) {
  const twitterAccount = node.data('twitterAccount')
  if (!twitterAccount) return 'No twitter info'
  const cs = twitterAccount.cursors
  if (!cs) return 'not started'
  // console.log('cs = %O, typo  %o', cs, typeof cs)

  const out = []
  for (const facet of ['friendsList', 'likes', 'tweets']) {
    let facetName = facet
    if (facetName === 'friendsList') facetName = 'followed'
    const c = cs[facet]
    let items = c ? c.items : 0
    if (items === undefined) items = '??' // items not properly maintained sometimes
    let ongoing = <span style={{ color: 'green', fontWeight: 'bold' }}>+++</span>
    if (c && c.done) {
      ongoing = ''
    }
    out.push(<span key={facet}><span>{items}</span>{ongoing} <span style={{ color: 'gray' }}>{facetName}</span> </span>)
  }
  return out
}

function Focused (props) {
  return <Diagram slot='cy1' all {...props} />

  /*
  const [ diagram, setDiagram ] = useParam('dia', props.param)
  console.log('Explore.Focused rendering, diagram =', diagram)

  // both at once?

  // I mean, integrate them?    selection ~= focus ?

  // also make it start with the focus selected?

  return (
    <>
      { /* broken because of stacking context, menu is under diagram
          <LimitDropdown client={props.client} />  }
      <button className={'ml-5 button is-rounded' + (diagram ? ' is-primary' : ' ')}
                onClick={() => setDiagram(prev => !prev)}>Diagram</button>
      { diagram
        ? <Diagram slot='cy1' all={true} {...props} />
        : <FocusNode {...props} />
      }
    </>
  )
  */
}
