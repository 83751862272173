import React from 'react'
import equal from 'fast-deep-equal'
// import CytoscapeComponent from 'react-cytoscapejs'
import stylesheet from './cy-style'
import cytoscape from 'cytoscape'
import { pct, U, useClient, ago, Account, accountSorter, useFocus } from './common'
import fcose from 'cytoscape-fcose'
import klay from 'cytoscape-klay'
import dbg from 'debug'
import coseb from 'cytoscape-cose-bilkent'
import cola from 'cytoscape-cola'
// import { useParam } from './params'
import { Portal } from 'react-portal'
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import delay from 'delay'
// import { useWindowWidth } from '@react-hook/window-size'
import rightArrow from './right-arrow.png'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

cytoscape.use(fcose)
cytoscape.use(coseb)
cytoscape.use(cola)
cytoscape.use(klay)

const diagram = {}
window.diagram = diagram

// Diagrams exist OUTSIDE the flow of the document, so they can be
// really stable as react is messing around with stuff. Cytoscape is
// pretty touchy.  You can't just umount/mount a cytoscape graph
// without the layout changing all over the place.
//
// The blank starting document should have an empty div with an id
// matching each "slot" (named persistant diagram) you're going to
// use.

export default class Diagram extends React.Component {
  constructor (props) {
    super(props)
    this.debug = dbg('Diagram-' + props.slot)
    // this.debug = (...x) => console.log(...x, 'DIAGRAM')
    this.debug('constructed, props=%o', props)
    // debug('constructed, props=%o', props)
  }

  get diagram () {
    const slot = this.props.slot
    if (!slot) window.alert('Diagram needs a slot')
    let d = diagram[slot]
    if (!d) {
      d = new PersistentDiagram(this.props)
      diagram[slot] = d
    }
    return d
  }

  componentDidMount () {
    // console.log('did mount')
    this.debug('did mount')
    // debug('did mount')
    this.diagram.show({ nav: this.props.nav })
  }

  componentWillUnmount () {
    // console.log('will unmount')
    this.debug('will unmount')
    this.diagram.hide()
  }

  render () {
    // console.log('render')
    this.debug('render')
    return (
      <>
        <Tippy content='Move the elements a little' delay={[250, 0]}>
          <button className='button is-rounded' onClick={() => {
            this.diagram.cy.zoom(0.95 * this.diagram.cy.zoom()) // make a litte jump
            this.diagram.touch();
          }}>Nudge</button>
        </Tippy>
          <button className='button is-rounded' onClick={() => this.diagram.trim(true)}>Only Focus</button>
        <Tippy content='Stop trying to display the oldest remembered focus' delay={[250, 0]}>
          <button className='button is-rounded' onClick={() => this.diagram.trim()}>Trim</button>
        </Tippy>
        <PathsControl diagram={this.diagram} />
        {/*
        <button onClick={() => this.diagram.hide()}>hide</button>
        <button onClick={() => this.diagram.show()}>show</button>
        <button onClick={() => this.diagram.lock()}>lock</button>
        <button onClick={() => this.diagram.pos()}>pos</button>
        <button onClick={() => this.diagram.unlock()}>unlock</button>
        <button onClick={() => this.diagram.cy.zoom(1)}>z1</button>
        <button onClick={() => this.diagram.cy.fit(null, 10)}>fit</button>
        <button onClick={() => {
          this.diagram.cy.fit(null, 15)
          this.diagram.layout.run()
          }}>re-layout</button> */}
        <AboutFocus style={{ position: 'fixed', top: this.props.nav.lowerPaneTop, bottom: this.props.nav.lowerPaneBottom, left: 0, right: 0, border: '1px solid white', overflow: 'clip' }} {...this.props} />
      </>
    )
  }
}

/*
function SelectionZoom (props) {
  const [selectedId, setSelectedId] = useParam('diagramSelect', props.params)

  if (!selectedId) return ''

  const [sourceid, targetid] = selectedId.split(' ')

  return (
    <Portal>
      <div style={{ position: 'absolute', right: 0, top: '5.5rem', width: 'min(60vw, 500px)', padding: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.85)', borderRadius: '0.5rem', boxShadow: '0 0 12px rgba(0, 0, 0, 0.5)', border: '1px solid gray', margin: '4px', zIndex: 3 }}>
        <button style={{ position: 'absolute', right: '0.5rem', top: '0.5rem', overflow: 'scroll' }} type='button' className='delete' aria-label='close' onClick={() => setSelectedId('')} />
        { targetid
          ? <EdgeZoom {...{ sourceid, targetid, setSelectedId, ...props }} />
          : <NodeZoom id={selectedId} {...{ setSelectedId, ...props }} />
        }
      </div>
    </Portal>
  )
}
*/

function AboutFocus (props) {
  const f = useFocus(props)

  if (f.sourceid) {
    return (
      <>
        <EdgeZoom {...{ ...f, ...props }} />
      </>
    )
  }

  if (f.id) {
    return <NodeZoom {...{ ...f, ...props }} />
  }
}

// consider https://www.npmjs.com/package/react-json-view
function OfferData ({ data }) {
  const [open, setOpen] = React.useState(false)

  window.appData = data

  return (
    <>
      <button className='button is-small' onClick={() => setOpen(!open)} style={{ color: '#AAA', position: 'fixed', top: '3.5rem', right: 0 }}>json</button>
      {open && <Portal>
        <div style={{ position: 'absolute', right: 0, top: '5.5rem', width: 'min(60vw, 500px)', padding: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.85)', borderRadius: '0.5rem', boxShadow: '0 0 12px rgba(0, 0, 0, 0.5)', border: '1px solid gray', margin: '4px', zIndex: 3 }}>
          <button style={{ position: 'absolute', right: '0.5rem', top: '0.5rem', overflow: 'scroll' }} type='button' className='delete' aria-label='close' onClick={() => setOpen(false)} />
          <pre>window.appData = {JSON.stringify(data, null, 2)}</pre>
        </div>
               </Portal>}
    </>
  )
}

// maybe display as side-by-side faces, to be more clear?!
function EdgeZoom (props) {
  const { sourceid, targetid, client } = props
  useClient(client)

  const source = client.gg2.obtainNode(sourceid)
  const target = client.gg2.obtainNode(targetid)
  const p = client.gg2.getEdgePayload(sourceid, targetid)
  if (!source || !target || !p) return '...'

  return (
    <div style={props.style}>
      <OfferData data={{ source, target, edgePayload: p }} />
      <div style={{ textAlign: 'center', overflowY: 'scroll', height: props.nav.lowerPaneHeight }}>
        <button style={{ position: 'absolute', left: '0.5rem', top: '0.5rem' }} type='button' className='delete' aria-label='close' onClick={() => props.setEdge()} />
        <div>

          <p><u>
            {p.prov === 'manually set' ? 'Stated ' : 'Implied '}
            Credibility Assessment
             </u>
          </p>

          <span onClick={() => { props.setEdge(); props.setFocusId(source.id) }}>
            <U node={source} {...props} />
          </span>
          <img style={{ height: '1rem', width: '5rem' }} src={rightArrow} alt='links to' />
          <span onClick={() => { props.setEdge(); props.setFocusId(target.id) }}>
            <U node={target} {...props} />
          </span>
          {p.knows === 2 && <p><i>Claim: We know each other</i></p>}
          {p.knows === 1 && <p><i>Claim: I know who they are</i></p>}
          {p.score !== undefined && <p><i>Credibility Score:</i> {pct(p.score)}</p>}
          {p.reason && <p><i>Reason:</i> {p.reason}</p>}
          {p.prov !== 'manually set' && <p><i>Implied by Twitter actions:</i> {p.prov}</p>}
          {p.lastModified && <p><i>Last modified:</i> {ago(p.lastModified)}</p>}

        </div>
      </div>
    </div>
  )
}

// could/should share a lot of code with FocusNode
function NodeZoom (props) {
  const { id, client, nav, setEdge } = props
  useClient(client)
  const node = client.gg2.obtainNode(id)

  if (!node) return '...'

  const inColumns = [
    { title: '@', field: 'endCred', width: '3em', headerTooltip: 'Computed credibility score of this assessor' },
    { title: 'Assessed by', field: 'source', formatter: reactFormatter(<Account client={client} nav={nav} />), sorter: accountSorter },
    {
      title: 'Says',
      field: 'rating',
      width: '3em',
      headerTooltip: 'What credibility score does this assessor give the selected entity',
      cellClick: (e, cell) => {
        setEdge(cell.getRow().getData().source.id, id)
      }
    }
  ]

  const outColumns = [
    { title: '@', field: 'endCred', width: '3em' },
    { title: 'Says...', field: 'target', formatter: reactFormatter(<Account client={client} nav={nav} />), sorter: accountSorter },
    {
      title: 'Says',
      field: 'rating',
      width: '3em',
      cellClick: (e, cell) => {
        setEdge(id, cell.getRow().getData().target.id)
      }
    }
  ]

  const insData = []; const outsData = []

  for (const [source, edgePayload] of node.ins || []) {
    const endCred = pct(source.payload.score)
    const rating = pct(edgePayload.score)
    insData.push({ endCred, rating, source })
  }

  for (const [targetid, edgePayload] of node.outs) {
    const target = client.gg2.obtainNode(targetid)
    const endCred = pct(target.payload.score)
    const rating = pct(edgePayload.score)
    outsData.push({ endCred, target, rating })
  }

  /*
  const rowClick = (event, row) => {
    // const id = row.getData().node.id
    // console.log('rowclick %o', event, row, row.getData())
    const data = row.getData()
    if (data.source) {
      // console.log('focus ins', data.source.id, id)
      setEdge(data.source.id, id)
    } else {
      // console.log('focus outs', id, data.target.id)
      setEdge(id, data.target.id)
    }
  }
  */

  // try to avoid a bunch of wasted gray space if there aren't many
  // rows. I don't know the right way to do this with tabulator. This
  // kinda works in most cases, I think.
  const h = rows => {
    // if (rows.length < 5) return undefined
    return '24vh'
  }

  const ins = { columns: inColumns, data: insData, options: { height: h(insData) } }
  const outs = { columns: outColumns, data: outsData, options: { height: h(outsData) } }

  const leftStyle = { position: 'absolute', left: 0, width: '49%', top: 0, bottom: 0 }
  const rightStyle = { position: 'absolute', right: 0, width: '49%', top: 0, bottom: 0 }

  return (
    <div style={props.style}>
      <OfferData data={{ id, node, insData, outsData }} />
      {insData.length
        ? <ReactTabulator style={leftStyle} {...ins} />
        : <div style={leftStyle} {...outs}><h2 style={{ padding: '2rem' }}>No incoming links available</h2></div>}
      {outsData.length
        ? <ReactTabulator style={rightStyle} {...outs} />
        : <div style={rightStyle} {...outs}><h2 style={{ padding: '2rem' }}>No outgoing links available</h2></div>}
    </div>
  )
}

// these are long lived -- created early in the life of the app and
// never closed
class PersistentDiagram {
  constructor ({ params, client, slot, all }) {
    this.client = client
    this.slot = slot
    this.params = params
    this.all = all

    // the debug module isnt working.  wtf.
    // this.debug = dbg('pd') //  + slot)
    // this.debug = (...x) => console.log(...x, 'PersistentDiagram')
    this.debug = () => {}

    window.d = this.debug
    this.debug('constructed')
    this.bestNPaths = 3

    this.focusHistory = []

    this.touch = this.touch.bind(this)
    this.updateLoop()

    this.client.on('change', this.touch)

    this.updateSelection = this.updateSelection.bind(this)
    this.updateSelection()
    params.focusNode.on('change', this.updateSelection)
    params.focusLink.on('change', this.updateSelection)
  }

  get div () {
    return document.getElementById(this.slot)
  }

  /*
  XXXupdate () {
    this.debug('update starts')
    if (!this.client) { this.debug('no client'); return }
    if (!this.client.user) { this.debug('no user'); return }
    if (!this.cy) { this.debug('no cy'); return }
    const gg2 = this.client.gg2
    if (!gg2) { this.debug('no gg2'); return }

    let gg
    if (false && this.all) {
      gg = gg2
    } else {
      if (!this.focusId) { this.debug('no focusId'); return }
      const focusNode = gg2.obtainNode(this.focusId, null)
      if (!focusNode) { this.debug('no focus node'); return }

      gg = gg2.subgraphLeadingIn(focusNode)
    }
    window.gg = gg

    // USE gg.computePatchTo (newer)  !!
    if (this.layout) this.layout.stop()
    this.cy.$().remove()

    for (const node of gg.nodes()) {
      const id = node.id
      const name = node.payload.screenName
      const score = node.payload.score
      // const classes = []
      // if (id === this.client.user.nodeid) classes.push('root')
      // console.log('classes=%o, id=%o', classes, id)
      const data = { id, name, score }
      this.cy.add({ group: 'nodes', data })
      this.debug('.. add node', data)
    }
    this.cy.getElementById(this.client.user.nodeid).addClass('root')

    for (const [source, target, p] of gg.edges()) {
      const id = source + ' ' + target
      const score = p.score
      const data = { id, source, target, score}
      this.cy.add({ group: 'edges', data })
      this.debug('.. add edge', source, target, score)
    }

    window.cy = this.cy
    this.firstUpdateDone = true
    this.cySelect()
    this.runLayout()
  }
  */

  async loadExtra (id) {
    const x = await this.client.rpc('fetchNode', { id, maxOuts: 10 })
    console.log('extra = %o', x)
  }

  /*
  // this gets called when the parameter has changed or the data has
  // changed. When the user selects something, the parameter is
  // changed, but cy has already made the selection
  cySelect (selectedId) {
    this.inCySelect = true // hack to prevent recursion
    this.debug('cySelect', { selectedId })
    if (selectedId === undefined) selectedId = this.params.data('diagramSelect')
    if (selectedId) {
      this.debug('selectedId', selectedId)
      this.loadExtra(selectedId)
      let selected = this.cy.getElementById(selectedId)
      if (!(selected && selected.size())) {
        this.loadSelection(selectedId) // just loading from gg2 so it's sync
        selected = this.cy.getElementById(selectedId)
      }
      if (selected && selected.size()) {
        this.debug('selected', selected)
        window.cys = selected
        const oldSelection = this.cy.$(':selected')
        if (!oldSelection.same(selected)) {
          oldSelection.unselect() // this triggers recurson!
          selected.select()
        }
      } else {
        console.log('selection STILL not in graph??', selectedId)
        this.cy.$(':selected').unselect()
      }
    } else {
      this.cy.$(':selected').unselect()
    }
    delete this.inCySelect
  }
*/

  trim (all) {
    if (all) {
      this.focusHistory = [this.focusNode.id]
    } else if (this.focusHistory.length > 1) {
      this.focusHistory.shift()
    }
    this.touch()
  }

  addHistory (id) {
    if (!this.focusHistory.includes(id)) {
      this.focusHistory.push(id)
      this.touch()
      this.client.loadExtraNode(id) // data should change in response, soon
    }
  }

  updateSelection () {
    if (!this.cy) return

    const focusId = this.params.focusNode.current
    if (focusId !== this.focusNode?.id) this.touch()

    const focusLink = this.params.focusLink.current
    const selectedId = focusLink || focusId

    if (focusId) {
      this.focusNode = this.client.gg2.obtainNode(focusId, { screenName: 'loading...' })
      this.addHistory(focusId)
    }
    if (focusLink) {
      const [sourceid, targetid] = selectedId.split(' ')
      this.addHistory(sourceid)
      this.addHistory(targetid)
    }

    this.updatingSelection = true // hack to prevent recursion
    this.debug('updatingSelect', { selectedId })
    if (selectedId) {
      this.debug('we have a selection')
      const selected = this.cy.getElementById(selectedId)
      if (selected && selected.size()) {
        this.debug('selected', selected)
        window.cys = selected
        const oldSelection = this.cy.$(':selected')
        if (!oldSelection.same(selected)) {
          oldSelection.unselect() // this triggers recurson!
          selected.select() // as does this
        }
      } else {
        console.log('selection STILL not in graph??', selectedId)
        this.cy.$(':selected').unselect() // triggers recusion
      }
    } else {
      this.cy.$(':selected').unselect() // triggers recusion
    }
    delete this.updatingSelection
  }

  /* THIS IS NEEDED AT SOME POINT, if node is found via search

  loadNode (id) { // which actually means load the whole path
    this.loadPaths(this.client.gg2.roots[0], id)
    this.loadPaths(id, this.focusId)
  }
  loadPaths (from, to) {
    // const load = this.client.gg2.graphConnecting(from, to, 6)  // maxpaths opt
    // set this.gg to union the load
    // adjust cy to match this new gg.
  }

  */

  cyInit () {
    if (!this.cy) {
      this.cy = cytoscape({
        container: this.div,
        elements: [],
        style: stylesheet,
        maxZoom: 2
      })
      this.cy.on('select', event => {
        if (!this.updatingSelection) {
          const id = event.target.id()
          if (event.target.isNode()) {
            this.debug('user cy-selected node', id)
            this.params.data('focusLink', '')
            this.params.data('focusNode', id)
          } else {
            this.debug('user cy-selected edge', id)
            this.params.data('focusLink', id)
          }
        }
      })
      this.cy.on('unselect', event => {
        this.debug('clearing user cy-selections')
        if (!this.updatingSelection) {
          this.params.data('focusLink', '')
          // this.params.data('focusNode', '')
        }
      })
    }
    this.updateSelection()
  }

  show ({ nav }) {
    this.debug('show', this.div)
    if (!this.div) throw Error('document need div with id ' + JSON.stringify(this.slot))

    this.cyInit()

    this.div.style.display = 'block'
    this.div.style.visibility = 'visible'
    this.div.style.position = 'absolute'
    this.div.style.width = '100%'

    // obviously we should be passing these down from App, or something
    // maybe we can ask nav?
    this.div.style.top = nav.upperPaneTop
    this.div.style.bottom = nav.upperPaneBottom
    this.div.style.border = '1px solid white'
    this.div.style.zIndex = '1'

    this.visible = true
  }

  hide () {
    this.debug('hide', this.div)
    if (this.div) {
      // It turns out Cytoscape layouts don't work with display=none,
      // I guess because they need to ask the browser about effective
      // geometry or something. So we have to use visibility=hidden
      // instead.
      //
      // OR just have layouts freeze while invisible, which I think
      // they might do in the current version anyway.

      // this.div.style.display = 'none'
      this.div.style.visibility = 'hidden'
    }
    this.visible = false
  }

  pos () {
    const root = this.cy.getElementById(this.client.gg2.roots[0])
    const focus = this.cy.getElementById(this.focusId)
    const box = this.cy.extent()
    root.position({
      x: box.x1 + root.outerWidth() / 2 + 10,
      y: box.y1 + root.outerHeight() / 2 + 10
    })

    focus.position({
      x: box.x2 - focus.outerWidth() / 2 - 10,
      y: box.y2 - focus.outerHeight() / 2 - 10
    })
  }

  lock () {
    const root = this.cy.getElementById(this.client.gg2.roots[0])
    const focus = this.cy.getElementById(this.focusId)
    root.lock()
    focus.lock()
  }

  posilock () {
    if (this.client &&
        this.client.gg2 &&
        this.client.gg2.roots[0] &&
        this.focusId) {
      this.pos()
      // this.lock()
    }
  }

  unlock () {
    this.cy.$(':locked').unlock()
  }

  subgraphToShow () {
    // gg = gg2.subgraphLeadingIn(focusNode)
    // extras -- or put those in client?

  }

  /*
    Modify this.cy as necessary to be the same as gg. Don't
  */
  alignCy (gg) {
    const cy = this.cy
    window.cy = cy
    const old = 'OLD'
    let kept = 0
    cy.elements().data(old, true)
    this.debug('alignCy to', gg.asciiDraw())

    const setCy = (id, ggdata, group) => {
      this.debug('.. %o %o %o', group, id, ggdata)
      const ele = this.cy.getElementById(id)
      if (ele.group()) {
        const cydata = ele.data()
        delete cydata[old]
        // remove things we use for rendering!  use a naming convention?
        delete cydata.msg
        if (!equal(cydata, ggdata)) {
          this.debug('.... exists, data was: %o', cydata)
          ele.removeData()
          ele.data(ggdata)
          kept++
        } else {
          this.debug('.... exists same data')
          ele.removeData(old)
          kept++
        }
      } else {
        this.debug('.... added')
        cy.add({ group, data: ggdata })
      }
    }

    for (const ggnode of gg.nodes()) {
      const id = ggnode.id
      const ggdata = {
        id,
        name: ggnode.payload.screenName,
        score: ggnode.payload.score
      }
      setCy(id, ggdata, 'nodes')
    }

    for (const [source, target, p] of gg.edges()) {
      const id = source + ' ' + target
      const score = p.score
      const ggdata = { id, source, target, score }
      setCy(id, ggdata, 'edges')
    }

    const toRemove = cy.elements(`[${old}]`)
    this.debug('.. removing %o obsolete elements %o', toRemove.size(), toRemove)
    toRemove.remove()

    if (this.client?.user?.nodeid) {
      this.cy.getElementById(this.client.user.nodeid).addClass('root')
    }

    // copies from updateSelection  :-(
    const focusId = this.params.focusNode.current
    const focusLink = this.params.focusLink.current
    const selectedId = focusLink || focusId
    const selected = this.cy.getElementById(selectedId)
    selected.select()

    this.debug('.. kept = %o', kept)
    return kept
  }

  assembleSubgraph () {
    const gg = new this.client.Graph()

    if (!this.focusNode) return gg
    if (!this.client?.gg2) return gg
    if (!this.client?.user?.nodeid) return gg

    const root = this.client.gg2.obtainNode(this.client.user.nodeid)
    if (!root) return gg

    this.debug('assembleSubgraph from %o', root.id)

    gg.setNodePayload(root.id, root.payload)
    gg.setNodePayload(this.focusNode.id, this.focusNode.payload)

    for (const end of this.focusHistory) {
      this.debug('.. paths to %o', end)
      this.client.gg2.obtainNode(end, { screenName: 'loading...' })
      const paths = sortedPaths(this.client.gg2, root, end)
      if (end === this.focusNode.id && this.controls) {
        this.controls.setPathsFound(paths.length)
      }
      let n = 0
      for (const path of paths) {
        this.debug('.. .. path: %o', path)
        if (++n > this.bestNPaths) break
        copyAlongPath(path, this.client.gg2, gg)
      }
    }
    gg.setIns()
    return gg
  }

  update2 () {
    this.debug('update2')
    if (this.layout) this.layout.stop()
    this.gg = this.assembleSubgraph()
    this.debug('first alignment pass')
    const kept = this.alignCy(this.gg)
    this.debug('second alignment pass -- better be nothing!')
    this.alignCy(this.gg)

    showScores(this.cy)
    this.updateSelection()

    let randomize = true
    if (this.layout && kept > 2) randomize = false

    this.layout = this.cy.makeLayout({ name: 'cose-bilkent', randomize, idealEdgeLength: 120 }) /*, quality: 'proof' */
    // this.posilock()
    this.layout.run()
    // await this.cy.promiseOn('layoutstop') -- would make it smoother?
    // this.unlock()
  }

  async updateLoop () {
    while (true) {
      if (this.visible && this.dataChanged) {
        this.dataChanged = false
        await this.update2()
        continue
      }
      await delay(100)
    }
  }

  touch () {
    this.dataChanged = true
  }
}

// copied then modified from osca-core/scoring.js

function showScores (cy, cutoff = 0.50) {
  // alter nodes to reflect the score
  cy.nodes().forEach(ele => {
    const score = ele.data('score')
    if (score === undefined) return

    if (ele.same(cy.$('.root'))) {
      ele.data('msg', '❤️ ' + ele.data('name') + ' ' + pct(score))
    } else {
      ele.data('msg', ele.data('name') + ' ' + pct(score))
    }

    if (score >= cutoff) {
      // hue 0..120 == red orange yellow green
      const h = Math.round(60 + Math.pow(score, 4) * 60)
      ele.style('background-color', `hsl(${h}, 90%, 50%)`)
    } else {
      const g = 210
      ele.style('background-color', `rgb(${g}, ${g}, ${g})`)
    }
  })

  // alter edges to reflect the score (although they didn't change here!)
  cy.edges().forEach(edge => {
    // edge.style('width', 1 + edge.data('score') * 10)
    edge.data('msg', pct(edge.data('score')))
  })
}

function copyAlongPath (path, from, to) {
  const n = idOrNode => from.obtainNode(idOrNode)
  let source = n(path[0])
  // console.log('copyAlongPath from', source.id, path)
  to.setNodePayload(source.id, source.payload)
  for (let i = 1; i < path.length; i++) {
    const target = n(path[i])
    // console.log('.. to ', target.id)
    if (target.id === n(path[0]).id) {
      console.error('undetected cycle in path', path)
      return
    }
    to.setNodePayload(target.id, target.payload)
    const edgePayload = from.getEdgePayload(source, target)
    if (source.id !== target.id) {
      to.setEdgePayload(source.id, target.id, edgePayload)
    }
    source = target
  }
}

// BUG WORKAROUND -- we shouldn't be given any paths with cycles
function pathCyclesThroughStart (path) {
  let source = path[0]
  for (let i = 1; i < path.length; i++) {
    const target = path[i]
    if (target.id === path[0].id) return true
  }
  return false
}

function PathsControl ({diagram}) {
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  diagram.controls = {
    setPathsFound: n => {
      diagram.pathsFound = n // odd place to stash this!
      forceUpdate()
    }
  }
  
  return (
    <>
      <span className='button' style={{cursor: 'default'}}>Max paths {diagram.bestNPaths} of {diagram.pathsFound}</span>
    <button className='button is-rounded' onClick={() => {
      diagram.bestNPaths = ++diagram.bestNPaths
      diagram.touch()
      forceUpdate()
    }}>+</button>
    <button className='button is-rounded'
            disabled={diagram.bestNPaths < 2}
            onClick={() => {
              diagram.bestNPaths = --diagram.bestNPaths
              diagram.touch()
              forceUpdate()
            }}>-</button>
    </>        
  )
}

function sortedPaths (gg, start, end) {
  const paths = []
  for (const path of gg.allPaths(start, end)) {
    if (pathCyclesThroughStart(path)) continue // bug workaround
    paths.push(path)
  }
  paths.sort(cmpPath)
  console.log('sortedPaths pre-dedup %O', paths.map(p => pdump(p)))
  const p2 = withoutConsecutiveDuplicates(paths, cmpPath)
  console.log('sortedPaths returning %O', p2.map(p => pdump(p)))
  return p2
}

function scoreOr50 (n) {
  const score = n?.payload?.score
  if (score === undefined) return .50
  return score
}

// best path is the one with the highest node scores, where the far
// end of the path matters first.
function cmpPath (a, b) {
  let i = 1
  while (true) {
    let c = -1 * (scoreOr50(a[a.length - i]) - scoreOr50(b[b.length - i]))
    if (c !== 0) {
      // console.log('cmpPath %o @%o %o %o', c, i, pdump(a), pdump(b))
      return c
    }
    i++

    // oh, let's ONLY look at the last hop   --- eh, interesting but eh.
    // if (i > 2) break
  }
  // console.log('cmpPath EQ @%o %o %o', i, pdump(a), pdump(b))
  return 0
}

function pdump (path) {
  const out = []
  for (const n of path) {
    out.push(n.payload.screenName+'_'+pct(n.payload.score))
  }
  return out.join('-')
}

function withoutConsecutiveDuplicates (a, cmp) {
  if (a.length === 0) return []
  let v = a[0]
  const out = [v]
  for (const e of a.slice(1)) {
    if (e !== v && cmp(e, v)) {
      out.push(e)
      v = e
    }
  }
  return out
}
