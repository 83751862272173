const { taper } = require('./taper')
const { Graph } = require('./graph')
const debug = require('debug')('score')

/**
   Given a list of edges like:

   [sourceid, targetid, { edgeScore }, previousRoundSourceScore]

   return a map() nodeid -> score
 */
async function computeNodeScores (...args) {
  let roots, edges, g
  if (args.length === 2) {
    [roots, edges] = args
    debug('computeNodeScores (%o), %o edges', roots, edges.length)
    g = new Graph({roots})
    for (const [s, t, p] of edges) {
      if (typeof p.score !== 'number') {
        // throw Error('missing score on edge ' + s + ' - ' + t)
        //
        // This can totally happen because users control their own
        // outgoing edge objects. They might not put a score field
        // in there.
        //
        // also, I guess we'll use that for score-requests
        //
        //console.warn('missing score on edge ' + s + ' - ' + t)
        continue
      }
      g.setEdgePayload(g.obtainNode(s, {}), g.obtainNode(t, {}), p)
    }
  } else {
    [g] = args
    debug('computeNodeScores graph (%o), %o edges', g.roots, g.edgeCount())
  }
  
  const scores = new Map()

  const visitor = (node, edge) => {
    debug('.. visitor\n..    node = %o\n..    via edge %o', node, edge)
    let score = 1
    if (edge) {
      const sourceScore = scores.get(edge[0].id) //  || edge[0].payload.score
      score = taper(sourceScore, edge[2].score)
    }
    node.payload.score = score
    scores.set(node.id, score)
    debug('.. .. set %o score to %o', node.id, score)
  }

  /*
  const edgeCompare = (a, b) => {
    // can we mangle the edge to cache the results of this?  dunno if that's safe
    // debug('.. scoreGraph:edgeCompare %O <-> %O', a, b)
    // if (typeof a[0].payload !== 'object') throw Error()
    // if (typeof b[0].payload !== 'object') throw Error()
    // if (a[2].score === undefined) throw Error()
    // if (b[2].score === undefined) throw Error()
    return (taper(b[0].payload.score, b[2].score) -
            taper(a[0].payload.score, a[2].score))
  }
  */

  await g.walkBestFirst(visitor, edgeCompare3)
  debug('scoreGraph result scores = %O', scores)
  return scores
}

// These are speed critical comparison functions, which should have
// the same underlying semantics, but work on slightly different data
// because of where they are used.  Obviously it would be nice to
// clean this up someday.

/*
  Which edge is more important?  Here, the sources are Nodes, that
  have a .payload.score

  Currently used in giving MTR scores to all the nodes in a graph.

  See also edgeCompare4
*/

const edgeCompare3 = (a, b) => {
  // edges are [source, _targetid, edgePayload]
  
  const bSourceScore = b[0].payload.score || 0
  const aSourceScore = a[0].payload.score || 0
  let c = bSourceScore - aSourceScore

  if (c === 0) {
    let c = (taper(bSourceScore, b[2].score) -
             taper(aSourceScore, a[2].score))
  }

  // copied from below, then modified to add .id   :-(
  if (c === 0) {
    if (typeof b[0] === 'number') {
      c = b[0] - a[0]
    } else {
      c = a[0].id.localeCompare(b[0].id, 'en')
    }
    
    if (c === 0) {
      if (typeof b[1] === 'number') {
        c = b[1] - a[1]
      } else {
        c = a[1].localeCompare(b[1], 'en')
      }
    }
  }

  if (isNaN(c)) throw Error('compare worked out to NaN in ec3')
  if (c === 0) throw Error('compare returing 0 in ec3')

  return c
}

/*
  Which edge is more important?  Here, the sources are ids, but the
  edge has a 4th element which is the source score.

  Currently used in directing a crawl.

  See also edgeCompare3
*/
const edgeCompare4 = (a, b) => {
  // console.log('a = %o', a)
  // console.log('b = %o', b)
  
  // edges are [sourceid, targetid, edgePayload, sourcePriority]
  
  // sort by sourcePriority
  // then edgePayload.score
  // then sourceid  (just so order is deterministic)
  // then targetid  (just so order is deterministic)

  let c = b[3] - a[3]
  // if (c !== 0) console.log('sourcePriority %o', c)

  // if you're trusted, but low confidence, don't count it
  if (b[3] > a[3] && b[2].score < 0.55 && b[2].score > 0.45) c = 0
  if (a[3] > b[3] && a[2].score < 0.55 && a[2].score > 0.45) c = 0
  
  if (c === 0) {
    // deal with undefined scores in a stable way
    const bScore = b[2].score || 0
    const aScore = a[2].score || 0
    c = bScore - aScore

    // if (c !== 0) console.log('edgePayload score %o', c)
    
    if (c === 0) {
      if (typeof b[0] === 'number') {
        c = b[0] - a[0]
      } else {
        c = a[0].localeCompare(b[0], 'en')
        // if (c !== 0) console.log('sourceid %o', c)
      }
      
      if (c === 0) {
        if (typeof b[1] === 'number') {
          c = b[1] - a[1]
        } else {
          c = a[1].localeCompare(b[1], 'en')
          // console.log('%o <=> %o == %o', a[1], b[1], c)
        }
      }
    }
  }

  if (isNaN(c)) throw Error('compare worked out to NaN in ec4')
  if (c === 0) throw Error('compare returing 0 in ec4')
  
  // console.log('ec4 %o %o %o', a, b, c)
  return c
}

module.exports = { computeNodeScores, edgeCompare4, edgeCompare3 }
